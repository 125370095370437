import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-alert-dialog',
  templateUrl: './alert-dialog.component.html',
  styleUrls: ['./alert-dialog.component.scss']
})
export class AlertDialogComponent implements OnInit {

  message:string;
  title: string;
  input:string;
  footermessage:string;

  constructor(public dialogRef: MatDialogRef<AlertDialogComponent>, @Inject(MAT_DIALOG_DATA) public data:any) {
    this.title = data.title;
    this.message=data.message;
    this.input = data.input;
    this.footermessage = data.footermessage;
    if(data.footermessage){
      dialogRef.disableClose = true;
    }
   }

  ngOnInit(): void {
  }

  onConfirm(): void {
    this.dialogRef.close(true);
  }
 

}
