import { User } from './models/user';
import { UserService } from './services/user.service';
import { Component, OnInit, OnDestroy } from '@angular/core';

import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { Subscription } from 'rxjs';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { ActivityType } from './models/ActivityType';
import { ActivityLogService } from './services/activity-log.service';
import { LoggingService } from './utilities/logging.service';
import { AlertDialogComponent } from 'src/app/glp-common/components/alert-dialog/alert-dialog.component';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { environment } from 'src/environments/environment';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  constructor(
    private dialog: MatDialog,
    private router: Router,
    private route: ActivatedRoute,
    private userService: UserService,
    private logService: ActivityLogService,
    private loggingService: LoggingService,
    public oidcSecurityService: OidcSecurityService,
  )
  {
    console.log('app component');
  }
  ngOnDestroy(): void {
    this.subscriptions.forEach((s) => s.unsubscribe());
    // this.oidcSecurityService.userData$.forEach((s) => s.unsubscribe());
  }
  title = 'group-leader-portal-ui';
  loggedIn = false;
  subscriptions: Subscription[] = [];



  
  ngOnInit() {

    const userdata = this.oidcSecurityService.userData$;
    var exaccount;
    this.oidcSecurityService.userData$.subscribe((u) => {
      const account = u;
      if (account && account.userData && (exaccount == null || (exaccount?.emails && account?.userData.emails && (exaccount?.emails[0] != account?.userData.emails[0])))) {
        this.userService
          .create(account?.userData)
          .subscribe(
            (re) => { 
              if(re === 'true'){
                const dialogConfig = new MatDialogConfig();
                dialogConfig.data = {
                  title: 'Thank you for registering',
                  message: 'Your access has been sent for review.  You will receive an email once your access is approved within 1-2 business days.',
                  footermessage: 'Close the Browser to Exit'
                };

                this.dialog.open(AlertDialogComponent, dialogConfig);
                this.dialog.afterAllClosed.subscribe(_=>   this.oidcSecurityService.logoff())
              }
              console.log(`user created : ${re}`)
          },
            (error) => { 
              this.router.navigate(['/not-approved']); 
            }
          );
      } 
      if(account && account.userData) {
      exaccount = account;
      }
    });

    if(document.URL.includes('/register')){
      let urlParams = new URLSearchParams(window.location.search)
      let callbackURL = urlParams.get('callback');

      if(callbackURL && callbackURL.length > 0){
        sessionStorage.setItem('sapURL', callbackURL);
      }
    }

    this.oidcSecurityService
    .checkAuth()
    .subscribe((authResult) => {
      if(!authResult.isAuthenticated)
      {

        if(document.URL.includes('/register') && sessionStorage.getItem('sapURL')){    
          if(!sessionStorage.getItem('sapURL').includes(environment['sapDomain'])){
            alert('Invalid SAP onboarding case provided. Please reach out to your agency for more information.');
            sessionStorage.removeItem('sapURL');
            this.router.navigate(['/']);
            this.oidcSecurityService.authorize();
          }
          else{
            this.router.navigate(['/register']);
          }
        }
        else if (document.URL.includes('/completeRegistration')){
          if(document.URL.includes('AADB2C90091')){ // This error is if the user clicks the 'cancel' button on the b2c registration form
            window.location.href = environment['redirectUrlBroker']; 
          }
          else{
            this.router.navigate(['/completeRegistration']);
          }
        }
        else{
          sessionStorage.setItem('preAuthPath', location.pathname.toLowerCase());
          this.oidcSecurityService.authorize();
        }

      }
      if (authResult.isAuthenticated) {
      
        var destinationRoute ='/not-approved';
      
        this.oidcSecurityService.getPayloadFromIdToken().subscribe((account) => {
          if (account) {
            let userPath = sessionStorage.getItem('preAuthPath') ?? location.pathname.toLowerCase();
            sessionStorage.removeItem('preAuthPath');

            if(sessionStorage.getItem('sapURL')){
              if(account.extension_Broker_IsBroker){
                if(account.extension_Broker_IsActivated){
                  sessionStorage.removeItem('brokerNeedsActivation');
                }
                destinationRoute = '/completeRegistration';
              }
              else{
                destinationRoute = '/home/activateAccount';
              }
            }
            else if(account.extension_Broker_IsBroker && account.extension_Broker_IsActivated){
              let validEndpoints = [
                '/home/contact',
                '/home/broker',
                '/home/maapplicationstatus'
              ];
              destinationRoute = validEndpoints.includes(userPath) ? userPath : '/home/broker'; 
            }
            else if(account.extension_AccountNumber != null){
              destinationRoute ='/home/GroupLeaderAccounts';
            }

            this.logService.log(account.emails[0], this.userService.isBrokerUser(), ActivityType.SignIn, `email: ${account.emails[0]}, destinationroute ${destinationRoute}, isbroker: ${account.extension_Broker_IsBroker}, isActivated ${account.extension_Broker_IsActivated}`).subscribe(
                (data) => {},
                (err) => {
                  this.loggingService.logException(err, 3);
            });
          }
        });

        this.loggedIn = true;
        this.router.navigate([destinationRoute]);
      }
    }); 
    
  }

  login() {
    this.router.navigate(['/autologin']);
  }

  refreshSession() {
    console.log('start refreshSession');
    this.oidcSecurityService.authorize();
  }

  logout() {
    this.oidcSecurityService.getPayloadFromIdToken().subscribe((account) => {
      let email = ' ';
      if (account?.emails?.length > 0) {
        email = account.emails[0];
      }
      console.log('start logoff');
      this.logService
        .log(email, this.userService.isBrokerUser(), ActivityType.SignOut, '')
        .subscribe({
          next: (data) => {},
          error: (err) => {
            this.loggingService.logException(err, 3);
          }
        });
      this.oidcSecurityService.logoff();

    });

  }
}
