import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { AppConfigService } from '../app.config.service';
import { LogRequest } from '../models/LogRequest';
import { delay,map } from 'rxjs/operators';
import { ActivityType } from '../models/ActivityType';

@Injectable({
  providedIn: 'root'
})
export class ActivityLogService {

  constructor(private http: HttpClient,
    private appConfigService: AppConfigService) {

  }

  log(userEmail:string, isBroker:boolean, activityType:ActivityType, actionValue: string): Observable<any> {
    let request:LogRequest = {
      userEmail:userEmail,
      isBroker:isBroker,
      activityType:activityType,
      actionValue:actionValue
    };

    return this.http.post(`${this.appConfigService.AppConfig.apiUrl_glp}/Log`, request);
  }
  
}

