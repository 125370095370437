import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsComponent } from './forms.component';
import { FormsListComponent } from './components/forms-list/forms-list.component';
import { MaterialModule } from '../../material/material.module';
import { TestComponent } from './components/test/test.component';


@NgModule({
  declarations: [FormsComponent, FormsListComponent, TestComponent],
  imports: [
    CommonModule,
    MaterialModule
  ]
})
export class FormsModule { }
