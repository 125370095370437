import { Component, OnInit } from '@angular/core';
import { UserService } from '../../services/user.service';
import { Router } from '@angular/router';
import { OidcSecurityService } from 'angular-auth-oidc-client';

@Component({
    selector: 'app-activateaccount',
    templateUrl: './activateaccount.component.html',
    styleUrls: ['./activateaccount.component.scss'],
  })
export class ActivateAccountComponent implements OnInit {
    constructor(
        private userService: UserService,
        private oidcSecurityService: OidcSecurityService,
        private router: Router
    ){}

    userNPN: number;
    firstName: string;
    lastName: string;
    isProcessing: boolean;
    alertMessage: string;
    ngOnInit() {
        this.oidcSecurityService.getPayloadFromIdToken().subscribe((account) => {
            if(!this.userService.isBrokerUser){
                this.router.navigate(['/home']);
            }
            else if(this.userService.isBrokerUser && this.userService.getBrokerApprovalStatus().isApproved){
                this.router.navigate(['/home/broker']);
            }
            this.isProcessing = false;
            this.firstName = account.given_name || account.extension_FirstName;
            this.lastName = account.family_name || account.extension_LastName;
        });


    }

    sendNPN(){
        let validForm = this.validateForm();
        if(validForm){
            this.isProcessing = true;
            this.userService.activateAccount(this.userNPN, this.firstName, this.lastName).subscribe(async res => {
                if(res.activated){
                    sessionStorage.removeItem('brokerNeedsActivation');
                    await this.userService.refreshSession();
                    return true;
                }
                else{
                    alert('Failed npn activation')
                    this.router.navigate(['/not-approved']);
                }
            });
        }
        else{
            alert(this.alertMessage);
        }
    }

    validateForm(): Boolean {
        let validForm = true;
        
        if(this.firstName.length < 3){
            validForm = false;
            this.alertMessage = "First name must be at least 3 characters.";
        }
        if(this.lastName.length < 3){
            validForm = false;
            this.alertMessage = "Last name must be at least 3 characters.";
        }
        if(this.userNPN < 999){
            validForm = false;
            this.alertMessage = "NPN must be at least 3 digits.";
        }

        return validForm;
    }
}

