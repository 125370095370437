import { JsonpClientBackend } from '@angular/common/http';
import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { MemberDataService } from '../../services/member-data.service';
import { MatPaginator } from '@angular/material/paginator';
import { OidcSecurityService } from 'angular-auth-oidc-client';

@Component({
  selector: 'app-member-information',
  templateUrl: './member-information.component.html',
  styleUrls: ['./member-information.component.scss']
})

export class MemberInformationComponent implements OnInit {
  memberInfo: any;
  message:string;
  title: string;
  input:string;
  footermessage:string;
  memberId: string;
  groupId: string;
  accountType: string;
  isShown: boolean = true ; // unhidden by default
  showBenefits: boolean = false;
  idType: string;
  productType: string;

  displayedColumns1: string[] = ['gender', 'relation', 'memberId', 'memberName', 'memberAddress', 'memberCityStPin', 'dateOfBirth', 'startDt', 'endDt'];
  dataSource1: MatTableDataSource<any>;

  displayedColumns2: string[] = ['coverageTypeTxt', 'coverageTierTxt', 'nationalContractPrefixCd', 'benefitPlanDesc', 'productDesc', 'coverageFromDt', 'coverageThruDt'];
  dataSource2: MatTableDataSource<any>;

  displayedColumns3: string[] = ['coverageTypeTxt', 'otherInsurancePolicyId', 'otherInsuranceName', 'classificationTxt', 'effectiveFromDt', 'effectiveThruDt'];
  dataSource3: MatTableDataSource<any>;

  @ViewChild(MatPaginator) covInfoPaginator: MatPaginator;

  constructor(public dialogRef: MatDialogRef<MemberInformationComponent>, @Inject(MAT_DIALOG_DATA) public data:any, private dataService: MemberDataService, public oidcSecurityService: OidcSecurityService) {
    this.memberInfo = data;
    this.title = data.record.memberName;
   }

  ngOnInit(): void {
    this.accountType = sessionStorage.getItem('accountType');
    this.groupId = sessionStorage.getItem('groupId');
    this.memberId = this.memberInfo.record.memberId;
    this.showBenefits = this.accountType?.toLowerCase() === 'aso' || this.accountType?.toLowerCase() === "both";

    if(this.accountType === 'FI')
    {
      this.isShown = false;
    }

      //default idType is groupId
      if(this.dataService.idType == undefined || this.dataService.idType == null){
        this.idType = 'groupId';
      }
      else{
        this.idType = this.dataService.idType;
      }

    this.dataService.getMemberInformation(this.memberInfo.record.memberId,this.groupId, this.idType).subscribe((list) =>
    {
      let array = [];
      if (list !== null && list.length > 0)
      {
        array = list.map((item) => {
          return {
                    $key: item.memberId,
                    ...item,
                 };
        });
      }
      this.dataSource1 = new MatTableDataSource(array);
    });

    if(this.idType == 'individualId'){
      this.productType = this.dataService.productType;
    }

    this.dataService.getMemberCoverageInfo(this.memberInfo.record.memberId, this.groupId, this.idType, this.productType).subscribe((list) =>
    {
      let array = [];
      if (list !== null && list.length > 0)
      {
        array = list.map((item) => {
          return {
                    $key: item.memberId,
                    ...item,
                 };
        });
      }
      this.dataSource2 = new MatTableDataSource(array);
      this.dataSource2.paginator = this.covInfoPaginator;
    });

    this.dataService.getMemberOtherInsurance(this.memberInfo.record.memberId,this.groupId, this.idType).subscribe((list) =>
    {
      let array = [];

      if (list !== null && list.length > 0)
      {
        array = list.map((item) => {
          return {
                    $key: item.memberId,
                    ...item,
                 };
        });
      }
      this.dataSource3 = new MatTableDataSource(array);
    });
  }

  onConfirm(): void {
    this.dialogRef.close(true);
  }
  
  handleGtagClick(click_location: string, click_action: string): void {
    window.gTagPush(click_location, click_action)
  }
}
