import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { AlertDialogComponent } from 'src/app/glp-common/components/alert-dialog/alert-dialog.component';
import { ActivityType } from 'src/app/models/ActivityType';
import { ActivityLogService } from 'src/app/services/activity-log.service';
import { UserService } from 'src/app/services/user.service';
import { LoggingService } from 'src/app/utilities/logging.service';

@Component({
  selector: 'app-not-approved',
  templateUrl: './not-approved.component.html',
  styleUrls: ['./not-approved.component.scss']
})
export class NotApprovedComponent implements OnInit {
  userEmail: string;
  constructor(
    private dialog: MatDialog,
    public oidcSecurityService: OidcSecurityService,
    private logService: ActivityLogService,
    private userService: UserService,
    private loggingService: LoggingService
  ) { }

  ngOnInit() {
    this.oidcSecurityService.getPayloadFromIdToken().subscribe((account)=>{
    // console.log('account: ' , account);
    if(account?.emails?.length>0){
      this.userEmail = account.emails[0];
    }
    this.logService.log(this.userEmail,this.userService.isBrokerUser(),ActivityType.RejectedLogin, this.userEmail).subscribe(
      (data) => {
      },
      (err) => {
                this.loggingService.logException(err, 3);
      }
    );
    const errorDialogConfig = new MatDialogConfig();

    errorDialogConfig.data = {
      title: (account.extension_Broker_IsBroker ? 'Pending' : 'Denied') + ' Account Access',
      message: 'Your account is currently under review and you will be notified once access is approved. ' + (account.extension_Broker_IsBroker ? '' : 'If access has been approved, your account may have been disabled. To learn more or reactivate your account, please contact your account team for further assistance.'),
      footermessage: 'Close the Browser to Exit'
    };
    this.dialog.open(AlertDialogComponent, errorDialogConfig);
    this.dialog.afterAllClosed.subscribe(_=>   this.oidcSecurityService.logoff())
  });
  }


}
