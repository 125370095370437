<link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap@4.3.1/dist/css/bootstrap.min.css" integrity="sha384-ggOyR0iXCbMQv3Xipma34MD+dH/1fQ784/j6cY/iJTQUOhcWr7x9JvoRxT2MZw1T" crossorigin="anonymous">

<section class="header-section fading-title parallax" style="margin-top: 30px">
    <div class="section-shade sep-top-4x sep-bottom-2x">
        <div class="container">
            <h2 class="light small-space">Activate Your Account</h2>
        </div>
    </div>
</section>

<div class="container">
    <h4>Complete broker registration by filling in the information below.</h4>
</div>

<div class="container">

    <div class="panel panel-default">
        <div class="panel-body">
            <hr />

            <div class="field row sep-top-sm">
                <label class="control-label col-md-offset-2 col-md-7" style="margin-bottom: 3px;">First Name</label>
                <div class="col-md-offset-2 col-md-7">
                    <input class="form-control input-lg text-box single-line" [(ngModel)]="firstName">
                </div>
            </div>

            <div class="field row sep-top-sm" style="margin-top: 10px;">
                <label class="control-label col-md-offset-2 col-md-7" style="margin-bottom: 3px;">Last Name</label>
                <div class="col-md-offset-2 col-md-7">
                    <input class="form-control input-lg text-box single-line" [(ngModel)]="lastName">
                </div>
            </div>

            <div class="field row sep-top-sm" style="margin-top: 10px;">
                <label class="control-label col-md-offset-2 col-md-7" style="margin-bottom: 3px;">NPN</label>
                <div class="col-md-offset-2 col-md-7">
                    <input type="number" class="form-control input-lg text-box single-line" [(ngModel)]="userNPN">
                </div>
            </div>

            <hr />

            <div class="field row sep-top-sm" style="margin-top: 10px;">
                <div class="col-md-offset-2 col-md-offset-2 col-md-7">
                    <input id="btnValidateNpn" (click)="sendNPN()" type="button" value="Activate" class="btn btn-info" />
                </div>
            </div>

            <div *ngIf="isProcessing" class="field row sep-top-sm">
                <div id="divProcessing" class="col-md-offset-2 col-md-offset-2 col-md-7">
                    <p>Processing, please wait . . . <img src="../../../../../assets/images/icons/loadingRipple.gif"></p>
                </div>
            </div>

        </div>
    </div>
</div>