

<div *ngIf="!loggedIn">
        <div id="container">
            <!-- <img src="../assets/images/landing/login-background.png" id="bg"> -->
            <div id="login-container">
            <mat-spinner></mat-spinner>
            <!-- <div id="inner-container">
                <img src="../assets/images/header/landing-logo.png" class="logo">
                <p id="welcome">Welcome</p>
                <div class="divButton" (click)="login()">
                    Log in to Employer Account
                </div>
            </div>    -->
        </div>
    </div>

</div>
<router-outlet ></router-outlet>
