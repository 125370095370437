<h1 mat-dialog-title style="text-transform: none;">
    {{title}}
</h1>

<div mat-dialog-content>
<p style="white-space: pre-line;">{{message}}</p>
</div>

<div mat-dialog-actions id="buttonsContainer">
    <div class="directButtonContainer">
        <button class="buttonFont imageButton" (click)="onDismiss();">Cancel</button>
    </div>
    <div class="directButtonContainer">
        <button class="buttonFont imageButton" (click)="onConfirm();">Accept</button>
    </div>
</div>