import { NgModule } from '@angular/core';
import { HomeModule } from './home/home.module';
import { FormsModule } from './forms/forms.module';
import { DocumentsModule } from './plan-documents/documents.module';
import { ReportsModule } from './reports/reports.module';
import { ExpandedNotificationsModule } from './expanded-notifications/expanded-notifications.module';
import { FaqModule } from './faq/faq.module';
import { BrokerModule } from './broker/broker.module';
import { MembersListModule } from './home/components/members-list/members-list.module';

@NgModule({
  declarations: [],
  imports: [
    HomeModule,
    FormsModule,
    DocumentsModule,
    ReportsModule,
    ExpandedNotificationsModule,
    FaqModule,
    BrokerModule,
    MembersListModule
  ]
})
export class ContentModule { }
