import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ExpandedNotificationsComponent } from './expanded-notifications.component';
import { MaterialModule } from '../../material/material.module';
import { ExpandedNotificationsListComponent } from './components/expanded-notifications-list/expanded-notifications-list.component';



@NgModule({
  declarations: [ExpandedNotificationsComponent, ExpandedNotificationsListComponent],
  imports: [
    CommonModule,
    MaterialModule
  ]
})
export class ExpandedNotificationsModule { }
