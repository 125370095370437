import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TokenInterceptor, CacheInterceptor } from './interceptors/token-interceptor';
import { environment } from './../environments/environment';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule, APP_INITIALIZER, ApplicationRef, ErrorHandler } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ContentModule } from './content/content.module';
import {
  //OidcConfigService,
  LogLevel,
  OidcSecurityService,
  AuthModule,
} from 'angular-auth-oidc-client';

import { LayoutModule } from './layout/layout.module';
import { MaterialModule } from './material/material.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { AppConfigService } from './app.config.service';
import { ErrorHandlerService } from './utilities/logging.service';
import { EmailDialogComponent } from './content/home/components/email-dialog/email-dialog.component';
import { SendDialogComponent } from './glp-common/components/send-dialog/send-dialog.component';
import { ActivateAccountComponent } from './content/activateAccount/activateaccount.component';
import { HomeModule } from './content/home/home.module';


export function loadConfigService(configService: AppConfigService): Function {
  return () => {
    return configService.load();
  };
}

const routeUser = () => {
  if(document.baseURI.includes('broker')){
    return environment.redirectUrlBroker;
  }
  else{
    return environment.redirectUrlGL;
  }
};

const routeSTS = () => {
  if(document.baseURI.includes('broker')){
    return environment.stsServerBroker;
  }
  else{
    return environment.stsServerGL;
  }
};

const routeAuth = () => {
  if(document.baseURI.includes('broker')){
    return environment.authWellknownEndpointBroker;
  }
  else{
    return environment.authWellknownEndpointGL;
  }
};

@NgModule({
  declarations: [AppComponent,EmailDialogComponent, SendDialogComponent, ActivateAccountComponent],
  imports: [
    BrowserModule.withServerTransition({ appId: 'ng-cli-universal' }),
    HttpClientModule,
    AuthModule.forRoot({
      config:{
        authority: routeSTS(),
        authWellknownEndpointUrl: routeAuth(),
        redirectUrl: routeUser(),
        postLogoutRedirectUri: window.location.origin,
        clientId:environment.b2cClientId,
        scope:environment.consentScopes,
        postLoginRoute: `${routeUser()}\home`,
        responseType: 'id_token token',
        silentRenew: true,
        autoUserInfo: false,
        silentRenewUrl: window.location.origin + '/silent-renew.html',
        logLevel: LogLevel.Debug,
        renewTimeBeforeTokenExpiresInSeconds: 60,
      },
    }),
    AppRoutingModule,
    ContentModule,
    FormsModule,
    ReactiveFormsModule,
    LayoutModule,
    MaterialModule,
    BrowserAnimationsModule,
    HttpClientModule
  ],

  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: CacheInterceptor,
      multi: true,
    },
    {
      provide: ErrorHandler,
      useClass: ErrorHandlerService
    },
    AppConfigService,
    {
      provide: APP_INITIALIZER,
      useFactory: loadConfigService,
      deps: [AppConfigService],
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
