import { Component, OnInit, Inject, ViewChild, ElementRef } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-send-dialog',
  templateUrl: './send-dialog.component.html',
  styleUrls: ['./send-dialog.component.scss'],  
})

export class SendDialogComponent implements OnInit {
  @ViewChild('emailidInput') emailidInput: ElementRef; 
  message:string;
  title: string;
  sendDialogForm: FormGroup;

  constructor(public dialogRef: MatDialogRef<SendDialogComponent>, @Inject(MAT_DIALOG_DATA) public data:any, private formBuilder: FormBuilder) {
    this.title = data.title;
    this.message = data.message;
    this.sendDialogForm = formBuilder.group({
      emailidInput : new FormControl('',[Validators.required, Validators.pattern(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)])
    })
   }

  ngOnInit(): void {
  }

  onConfirm(): void {
    var result =  { 
                    Email : this.emailidInput.nativeElement.value,
                    Closed : true
            };   
    this.dialogRef.close(result);
  }
 
  onDismiss(): void {
    var result =  { 
                    Email : this.emailidInput.nativeElement.value,
                    Closed : false
                };
    this.dialogRef.close(result);
  }

}
