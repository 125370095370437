<div class="center">
    <mat-spinner *ngIf="isLoading" class="custom-spinner" ></mat-spinner>
</div>

<div id="container">
    <div id="closeIconDiv">
        <button class="close-icon" [mat-dialog-close]="true" tabindex="-1" (click)="handleGtagClick('Member Profile','Close - Step 2')">X</button>
    </div>  
    
    <div id="titleDiv">Member Profile</div>

    <div id="memberIdLabelDiv"><label>Member ID:</label></div>
    <div id="memberIdValueDiv" class="value">{{memberInfo.memberId}}</div>

    <div id="accountIdLabelDiv"><label>Account ID:</label></div>
    <div id="accountIdValueDiv" class="value">{{memberInfo.accountId}}</div>

    <!-- <div id="nameLabelDiv"><label>Name:</label></div>
    <div id="nameValueDiv" class="value">{{memberInfo.name}}</div> -->

    <div id="addressLabelDiv"><label>Address:</label></div>
    <div id="addressValueDiv" class="value">{{memberInfo.address}}</div>

    <!-- <div id="dobLabelDiv"><label>DOB:</label></div>
    <div id="dobValueDiv" class="value">{{memberInfo.dateOfBirth}}</div> -->

    <div id="coverageTypeLabelDiv"><label>Coverage Type:</label></div>
    <div id="coverageTypeValueDiv" class="value">{{memberInfo.coverageType}}</div>

    <div id="coverageStartLabelDiv"><label>Coverage Start:</label></div>
    <div id="coverageStartValueDiv" class="value">{{memberInfo.coverageStart}}</div>

    <div id="coverageEndLabelDiv"><label>Coverage End:</label></div>
    <div id="coverageEndValueDiv" class="value">{{memberInfo.coverageEnd}}</div>

    <!-- <div id="dependentLabelDiv"><label>Dependent:</label></div>
    <div id="dependentValueDiv" class="value">{{memberInfo.dependent}}</div> -->

    <div id="empty1Div"></div>
    <div id="membersDiv">Members Information:</div>
    
    <div id="membersValueDiv">
        <table mat-table class="mat-elevation-z8" [dataSource]="dataSource">

            <!-- memberId Column -->
            <ng-container matColumnDef="memberId">
                <th mat-header-cell *matHeaderCellDef>Member Id</th>
                <td mat-cell *matCellDef="let element">{{element.memberId}}</td>
            </ng-container>

            <!-- Gender Column -->
            <ng-container matColumnDef="gender">
                <th mat-header-cell *matHeaderCellDef>Gender</th>
                <td mat-cell *matCellDef="let element">{{element.gender}}</td>
            </ng-container>
        
            <!-- Membername Column -->
            <ng-container matColumnDef="memberName" >
                <th mat-header-cell *matHeaderCellDef>Name</th>
                <td mat-cell *matCellDef="let element">{{element.memberName}}</td>
            </ng-container>
        
            <!-- Relation Column -->
            <ng-container matColumnDef="relation" >
                <th mat-header-cell *matHeaderCellDef>Relation</th>
                <td mat-cell *matCellDef="let element">{{element.relation}}</td>
            </ng-container>
        
            <!-- DOB Column -->
            <ng-container matColumnDef="dateOfBirth" >
                <th mat-header-cell *matHeaderCellDef>Date Of Birth</th>
                <td mat-cell *matCellDef="let element">{{element.dateOfBirth}}</td>
            </ng-container>

            <ng-container matColumnDef="loading">
                <mat-footer-cell *matFooterCellDef colspan="5">
                  Loading data...
                </mat-footer-cell>
            </ng-container>
            <ng-container matColumnDef="noData">
                <mat-footer-cell *matFooterCellDef colspan="5">
                  No data.
                </mat-footer-cell>
            </ng-container>
       
            <tr mat-header-row style="height: 22px; font-weight: bold" *matHeaderRowDef="displayedColumns" ></tr>   
            <tr mat-row style="height: 20px" *matRowDef="let row; columns: displayedColumns" (click)="openDetails(row)" [ngClass]="{'selectedRow':row.memberId==selectedMemberId,'termedMember': row.isTermedMember}" (click)="handleGtagClick('Members Information Table','Row Click - Step 2')"></tr>
            <mat-footer-row *matFooterRowDef="['loading']" [ngClass]="{'hide':dataSource!=null}"></mat-footer-row>
            <mat-footer-row *matFooterRowDef="['noData']" [ngClass]="{'hide':!(dataSource!=null && dataSource.data.length==0)}"></mat-footer-row>

        </table>
        
        <mat-paginator style="height: 20px"
                       [pageSize]="5" 
                       [pageSizeOptions]="[5]"
                       [showFirstLastButtons]="true" 
                       [hidePageSize]="true">
        </mat-paginator>
                                  
    </div>

    <!-- <div id="empty2Div"></div> -->
    <br/>
    <br/>
    <div id="actionsDiv">Actions:</div>
    <div id="orderCardDiv" class="buttonFont imageButton" [ngClass]="{'disableTermed': memberInfo.isTermedMember}" (click)="orderCard();" (click)="handleGtagClick('Member Profile Action','Order ID Card - Step 2')">
        <a><img class="imagePadding" src="../../../../../assets/images/icons/order.png"> Order ID Card</a>
    </div>
    <div id="emailCardDiv" class="buttonFont imageButton" [ngClass]="{'disableTermed': memberInfo.isTermedMember}" (click)="emailCard();" (click)="handleGtagClick('Member Profile Action','Email ID Card - Step 2')">
      <a><img class="imagePadding" src="../../../../../assets/images/icons/email-white.png"> Email ID Card</a>
    </div>
    <div id="downloadDiv" class="buttonFont imageButton" [ngClass]="{'disableTermed': memberInfo.isTermedMember}" (click)="downloadCard();" (click)="handleGtagClick('Member Profile Action','Download Member ID Card - Step 2')">
        <a><img class="imagePadding" src="../../../../../assets/images/icons/download.png"> Download ID Card</a>
    </div>
    <div id="findDoctorDiv" class="buttonFont imageButton" [ngClass]="{'disableTermed': memberInfo.isTermedMember}" (click)="findDoctor();" (click)="handleGtagClick('Member Profile Action','Find A Doctor - Step 2')">
        <a><img class="imagePadding" src="../../../../../assets/images/icons/doctor.png"> Find a Doctor</a>
    </div>
</div>

