<div class="container">
  <h1>Contact Us</h1>
  <div class="outersection">
    <div class="section">
      <span class="fa-stack">
        <i class="fa fa-circle fa-stack-2x" style="color: #005172; "></i>
        <i class="fa fa-phone fa-stack fa-stack-1x text-white"></i>
      </span>
      <h2>Call Us</h2>
      <p>Monday through Friday<br>8:00 a.m. to 5:00 p.m. CT<br>888-232-0942</p>
    </div>
    <div class="section">
      <span class="fa-stack">
        <i class="fa fa-circle fa-stack-2x" style="color: #005172; "></i>
        <i class="fa fa-envelope fa-stack fa-stack-1x text-white"></i>
      </span>
      <h2>Email Us</h2>
      <p>If you have questions please email us at <br>groupleader@nebraskablue.com</p>
    </div>
    <div class="section">
      <span class="fa-stack">
        <i class="fa fa-circle fa-stack-2x" style="color: #005172; "></i>
        <i class="fa fa-map-pin fa-stack fa-stack-1x text-white"></i>
      </span>
      <h2>Office Address</h2>
      <p>1919 Aksarben Drive<br>Omaha, NE 68180<br>
    </div>
  </div>
</div>
