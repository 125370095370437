import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { forkJoin, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { UserService } from '../services/user.service';
import { ActivityType } from '../models/ActivityType';
import { ActivityLogService } from '../services/activity-log.service';
import { LoggingService } from '../utilities/logging.service';

@Injectable({ providedIn: 'root' })
export class MasterAuthorizationGuard implements CanActivate {
  constructor(
    private oidcSecurityService: OidcSecurityService,
    private userService: UserService,
    private router: Router,
    private logService: ActivityLogService,
    private loggingService: LoggingService
  ) { }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> {

    let userAccount;
    this.oidcSecurityService.getPayloadFromIdToken().subscribe((account) => {
      userAccount = account;
    });

    if(this.userService.isGroupLeaderUser()){
      return forkJoin({
        isAuthorized: this.oidcSecurityService.checkAuth(),
        groupLeaderApprovalStatus: this.userService.getGroupLeaderApprovalStatus(),
      }).pipe(
        map((result) => {
            if (!result.isAuthorized.isAuthenticated || !result.groupLeaderApprovalStatus.isApproved) {
            this.router.navigate(['/not-approved']);
            return false;
          }
  
          return true;
        })
      );
    }
    if(this.userService.isBrokerUser()){
      return forkJoin({
        isAuthorized: this.oidcSecurityService.checkAuth(),
  
      }).pipe(
        map((result) => {
  
          if (!result.isAuthorized.isAuthenticated) {
            this.router.navigate(['/not-approved']);
            return false;
          }

          return true;
        })
      );
    }
  }
}
