import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { AppConfigService } from '../../../app.config.service';
import { GLPExpandedNotification } from '../models/GLPExpandedNotification';
import { delay,map } from 'rxjs/operators';
import * as SampleJson from "../../../../assets/json/response.json";

@Injectable({
  providedIn: 'root'
})
export class ExpandedNotificationsDataService {

  private settingsUrl: string;
  private reportUrl: string;

  constructor(private http: HttpClient,
    private appConfigService: AppConfigService) {

  }

  getNotifications(): Observable<GLPExpandedNotification[]> {
    return this.http.get<GLPExpandedNotification[]>(`${this.appConfigService.AppConfig.apiUrl_glp}/Notifications`);
  }

}

