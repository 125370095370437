import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { AppConfigService } from '../../../app.config.service';
import { DocumentInfo } from '../models/DocumentInfo';
import { delay,map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class DocumentDataService {

  private settingsUrl: string;
  private reportUrl: string;

  constructor(private http: HttpClient,
    private appConfigService: AppConfigService) {

  }


  getDocumentList(groupId:string, idType:string): Observable<DocumentInfo[]> {

    return this.http.get<DocumentInfo[]>(`${this.appConfigService.AppConfig.apiUrl_glp}/ListDocuments`, {
      params: new HttpParams()
          .set('groupid', groupId)
          .set('type', idType)
    });

    // let mockList:DocumentInfo[]=[
    //   { name:'Doc Name 1', fullName:'full/Doc Name 1' },
    //   { name:'Doc Name 2', fullName:'full/Doc Name 2' },
    //   { name:'Doc Name 3', fullName:'full/Doc Name 3' },
    //   { name:'Doc Name 4', fullName:'full/Doc Name 4' },
    //   { name:'Doc Name 5', fullName:'full/Doc Name 5' },
    //   { name:'Doc Name 6', fullName:'full/Doc Name 6' },
    //   { name:'Doc Name 7', fullName:'full/Doc Name 7' },
    //   { name:'Doc Name 8', fullName:'full/Doc Name 8' },
    //   { name:'Doc Name 9', fullName:'full/Doc Name 9' },
    //   { name:'Doc Name 10', fullName:'full/Doc Name 10' },
    //   { name:'Doc Name 11', fullName:'full/Doc Name 11' },

    // ];


    // return of(mockList).pipe(delay(2000));
  }

  getDocumentFile(fileName:string,fullFileName:string,userEmail:string,groupId: string, idType:string): Observable<any> {

    return this.http.get(`${this.appConfigService.AppConfig.apiUrl_glp}/GetDocument`, {
      observe: 'response',
      responseType: 'blob',
      params: new HttpParams()
          .set('fileName', fileName)
          .set('fullFileName', fullFileName)
          .set('userEmail', userEmail)
          .set('groupId', groupId)
          .set('type', idType)
    }).pipe(map(res=>{

      let data = {
        image: new Blob([res.body], {type: res.headers.get('Content-Type')})
      }
      return data ;
    }));

  }

}

