import { Component, ElementRef, Injectable, Input, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { UserService } from 'src/app/services/user.service';
import { environment } from 'src/environments/environment';


@Injectable({
  providedIn: 'root'
})

@Component({
  selector: 'app-completeRegistration',
  templateUrl: './completeRegistration.component.html',
})
export class CompleteRegistrationComponent implements OnInit {

  public showSpinner: Boolean = false;
  constructor(
    private userService: UserService,    
    public oidcSecurityService: OidcSecurityService, 
    private router: Router) {
  }

  ngOnInit() {
    this.oidcSecurityService.getPayloadFromIdToken().subscribe((account) => {
      if(account.extension_Broker_IsBroker && account.extension_Broker_NPN && sessionStorage.getItem('brokerNeedsActivation') && sessionStorage.getItem('brokerNeedsActivation') == 'true'){
        this.showSpinner = true;
        console.log('Activating account');
        this.userService.activateAccount(account.extension_Broker_NPN, account.extension_FirstName, account.extension_LastName).subscribe(async res => {
          if(res.activated){
            console.log('Redirecting after creation');
            let sapURL = sessionStorage.getItem('sapURL')
            sessionStorage.removeItem('sapURL');
            sessionStorage.removeItem('brokerNeedsActivation');
            window.location.href = sapURL;
          }
          else{
              alert('Failed account setup');
              this.router.navigate(['/not-approved']);
            }
        },
        (error) => {
          alert('Error');
        });
      }
      else if (account && account.oid){
        console.log('Redirecting, no creation needed');
        let sapURL = sessionStorage.getItem('sapURL')
        sessionStorage.removeItem('sapURL');
        window.location.href = sapURL;
      }
      else{
        this.oidcSecurityService.authorize();
      }
    })

  }

}
