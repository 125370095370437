import { Component, OnInit, ViewChild, ChangeDetectorRef } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { UserService } from '../../../../services/user.service';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MemberDataService } from '../../services/member-data.service';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { Router } from '@angular/router';
import { GroupLeaderInfo } from './GroupLeaderInfo';

@Component({
  selector: 'app-groupleaderlist',
  templateUrl: './groupleaderlist.component.html',
  styleUrls: ['./groupleaderlist.component.scss']
})
export class GroupleaderlistComponent implements OnInit {
  dataSource: MatTableDataSource<GroupLeaderInfo>;
  displayedColumns: string[] = [
    'GroupName',
    'GroupID',
    'MGN',
    'RenewalMonth',
    'MemberCount'
  ];
  groupList: any[];
  loaded = false;

  months = [
    "-Select Month-",
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December"
  ];
  groupNames = [];
  groupIds = [];
  mgns = [];
  user = "";
  filteredValues =
    {
      groupName: '',
      groupID: '',
      mgn: '',
      renewalMonth: ''
    };
  filterStr = '';


  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator; // For pagination
  @ViewChild(MatSort, { static: false }) sort: MatSort; // For Sort
  constructor(
    private MemberDataService: MemberDataService,
    public dataService: UserService,
    private router: Router,
    public oidcSecurityService: OidcSecurityService,
    private cdr: ChangeDetectorRef

  ) {}



  ngOnInit(): void {
    //this.groupNames.push('-Select Group Name-');
    //this.groupIds.push('-Select Group ID-');
    //this.mgns.push('-Select MGN-');

    this.oidcSecurityService.getPayloadFromIdToken().subscribe((account) => {
      
      this.user = account.extension_FirstName + " " + account.extension_LastName
      
      this.dataService.getGroupLeaderGroups(account.emails[0], account.extension_AccountNumber).subscribe((list) => {
        this.loaded = true;

        this.cdr.detectChanges();

        let groups = [];
        this.groupList = list;
        
        list.forEach(group => {
          let newGroup = {
            GroupName: this.toCamelCase(group.groupName),
            GroupID: group.groupId,
            MGN: group.mgn,
            RenewalMonth: group.renewalMonth,
            MemberCount: group.memberCount
          }

          //this.groupNames.push(group.groupName);
          //this.groupIds.push(group.groupId);
          //this.mgns.push(group.mgn);

          groups.push(newGroup);
          
        })
        if (groups.length == 1) {
          
          this.MemberDataService.selectedGroupID = groups[0].GroupId;
          this.MemberDataService.selectedGroupName = groups[0].GroupName;

          this.MemberDataService.notify({ isReload: true });
          this.dataService.setGroupLeaderAccountIsSelected(true);
          this.router.navigate(['/home']);
        }
        
        this.dataSource = new MatTableDataSource(groups);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
        this.dataSource.filterPredicate = this.customFilterPredicate();

        this.dataSource.sortingDataAccessor = (item, property) => {
          switch (property) {
            case 'RenewalMonth': {
              return this.months.indexOf(item.RenewalMonth);
            }
            case 'MemberCount':{
              return parseInt(item[property]);
            }
            default: return item[property];
          }
        };
      })
    });
    
  }
  
  toCamelCase(name: string): string {
    return name.replace(/\w\S*/g, function(txt){return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();});
  }
  
  customFilterPredicate() {

    const myFilterPredicate = (data: GroupLeaderInfo, filter: string): boolean => {
      var globalMatch = !this.filterStr;

      if (this.filterStr) {
        // search all text fields
        var globalMatch =
          (typeof data.GroupName !== 'undefined' && data.GroupName !== null && data.GroupName.toLowerCase().includes(this.filterStr.toLowerCase()) && this.toCamelCase(data.GroupName).toLowerCase().includes(this.filterStr.toLowerCase()) ||
          (typeof data.GroupID !== 'undefined' && data.GroupID !== null && data.GroupID.toLowerCase().includes(this.filterStr.toLowerCase())) ||
          (typeof data.MGN !== 'undefined' && data.MGN !== null && data.MGN.toLowerCase().includes(this.filterStr.toLowerCase())) ||
          (typeof data.RenewalMonth !== 'undefined' && data.RenewalMonth !== null && data.RenewalMonth.toLowerCase().includes(this.filterStr.toLowerCase())) 
         
          );
      }
     
      if (!globalMatch) {
        return;
      }
      let allColumnsMatch = true;

      return allColumnsMatch;
    }
    return myFilterPredicate;
  }

  
  ngAfterViewInit(): void {
    this.dataSource.paginator = this.paginator; // For pagination
    this.dataSource.sort = this.sort;    
  }

  openMemberList(groupId: number, groupName: string): void {
    let selectedGroup = this.groupList.filter(n => n.groupId == groupId.toString())[0];
    this.MemberDataService.selectedGroupID = groupId;
    this.MemberDataService.selectedGroupName = groupName;
    sessionStorage.setItem('accountType', selectedGroup.groupType);

    this.MemberDataService.notify({ isReload: true });
    this.dataService.setGroupLeaderAccountIsSelected(true);
    this.router.navigate(['/home']);
  }

  applyFilter() {
    this.dataSource.filter = JSON.stringify(this.filteredValues);
    
  }

  updateSearchText(filterValue: string) {
    this.filterStr = filterValue.trim();
    this.applyFilter();
  }
}
