import { HttpClient } from '@angular/common/http';
import { Injectable, ɵgetInjectableDef } from '@angular/core';
import { environment } from '../environments/environment';
import { AppConfig } from './models/app-config';

//export let ADAL_CONFIG: adal.Config;
export let ADAL_CONFIG: any;

@Injectable()
export class AppConfigService {
  private _appConfig: AppConfig;

  get AppConfig(): AppConfig {
    return this._appConfig;
  }

  constructor(private http: HttpClient) { }

  public load() {
    return new Promise((resolve, reject) => {
      this.mapConfigs(environment);
      resolve(true);

      // if (!environment.production) {
      //   this.mapConfigs(environment);
      //   resolve(true);
      // }

      //   this.http.get('assets/appSettings.php').subscribe(
      //     (envResponse: any) => {
      //       this.mapConfigs(envResponse);
      //       resolve(true);
      //     },
      //     error => {
      //       reject(true);
      //     }
      //   );
    });

  }

  private mapConfigs(configs: any): void {
    ADAL_CONFIG = {} as any;

    ADAL_CONFIG.cacheLocation = 'localStorage';
    ADAL_CONFIG.endpoints = {};
    ADAL_CONFIG.endpoints[configs.apiUrl] = 'true';
    ADAL_CONFIG.endpoints[configs.imageUrl] = 'true';

    this._appConfig = new AppConfig(configs.apiUrl, configs.imageUrl, 'yyyy/MM/dd', '1.0-0');
  }
}
