import {
  Component,
  ElementRef,
  OnInit,
  ViewChild,
} from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { UserService } from 'src/app/services/user.service';
import { trigger, transition, animate, style, state } from '@angular/animations'


@Component({
  selector: 'app-application-status',
  templateUrl: './applicationstatus.component.html',
  styleUrls: ['./applicationstatus.component.scss'],
  animations: [
    trigger('detailExpand', [
    state('collapsed', style({ height: '0px', minHeight: '0', visibility: 'hidden' })),
    state('expanded', style({ height: '*', visibility: 'visible' })),
    transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],


})



export class MAApplicationStatusComponent implements OnInit {
  // Data
  filteredValues =
  {
    applicationStatus: '',
  };
  displayedColumns = [
      'applicationNum', 'firstNameTxt', 'lastNameTxt', 'birthDt', 'applicationStatusTxt', 'icon' ];
  expandedDisplayedColumns = [
      'phone', 'planInfo', 'coverageDate', 'applicationDate' ];
  applications: any[] = [];
  expandedApplication: any;
  dataSource: MatTableDataSource<any>;
  groupId: number;
  enableSearch: boolean;
  user: string;
  searchText: string;
  brokerIdFilter: string;
  filterStr = '';
  animationState = 'in';
  pageIndex = 0;
  pageSize = 10;
  sortDirection = 'asc';
  sortProperty = 'applicationNum'
  dataCount = 0;

  @ViewChild(MatPaginator, {static: false}) paginator: MatPaginator; // For pagination
  @ViewChild(MatSort, {static: false}) sort: MatSort; // For Sort

  @ViewChild('searchInput', {static: false}) searchInput: ElementRef;

  constructor(private userService: UserService) {}
  
  ngOnInit() {
    this.userService.getMAApplicationStatuses().subscribe(async res => {
      if(res && res.retList){
        for(let i = 0; i < res.retList.length; i++){
          res.retList[i].birthDt = res.retList[i].birthDt ? new Date(res.retList[i].birthDt).toLocaleString().split(',')[0] : '';
          res.retList[i].requestedDtOfCoverage = new Date(res.retList[i].requestedDtOfCoverage).toLocaleString().split(',')[0];
          res.retList[i].receiptDt = new Date(res.retList[i].receiptDt).toLocaleString().split(',')[0];
          res.retList[i].primaryHomePhone = res.retList[i].primaryHomePhone ? '(' + res.retList[i].primaryHomePhone.substring(0, 3) + ') ' + res.retList[i].primaryHomePhone.substring(3,6) + '-' + res.retList[i].primaryHomePhone.substring(6, 10) : '';
        }
        this.applications = res.retList;

        this.setDataSource();
      }
    });
  }
  
  setDataSource(){
    const data = this.applications;
    const isAsc = this.sortDirection === 'asc';

    let sortedData = data.sort((a, b) => {
      switch (this.sortProperty) {
        case 'applicationNum':
          return this.compare(a.applicationNum, b.applicationNum, isAsc);
        case 'firstNameTxt':
          return this.compare(a.firstNameTxt, b.firstNameTxt, isAsc);
        case 'lastNameTxt':
          return this.compare(a.lastNameTxt, b.lastNameTxt, isAsc);
        case 'birthDt':
          return this.compare(new Date(a.birthDt), new Date(b.birthDt), isAsc);
        case 'applicationStatusTxt':
          return this.compare(a.applicationStatusTxt, b.applicationStatusTxt, isAsc);
        default:
          return 0;
      }
    });

    let filteredData = sortedData.filter(n => this.filterData(n));
    this.dataCount = filteredData.length;

    if(this.dataCount < (this.pageSize*this.pageIndex)){
      this.paginator.firstPage();
    }
    
    let shownApplications = filteredData.slice(this.pageSize*this.pageIndex, (this.pageSize*this.pageIndex) + this.pageSize);
    let output: object[] = [];
    
    for(let i = 0; i < shownApplications.length; i++){
      output.push(shownApplications[i], { detailRow: true, maApplication: shownApplications[i] });
    }

    this.dataSource = new MatTableDataSource(output);        
  }
  
  isExpansionDetailRow = (i: number, row: any) => {
    return row.hasOwnProperty('detailRow') && row.detailRow;
  }

  handlePagination(event: any){
    this.pageIndex = event.pageIndex;
    this.pageSize = event.pageSize;
    this.setDataSource();
  }
  
  sortData(sort: Sort) {
    this.sortDirection = sort.direction;
    this.sortProperty = sort.active;
    this.setDataSource()
  }

  compare(a: number | string | Date, b: number | string | Date, isAsc: boolean) {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }

  updateSearchText(filterValue: string){
    this.filterStr = filterValue.trim();
    this.setDataSource();
  }

  updateApplicationStatusFilter(applicationStatus: string){
    this.filteredValues.applicationStatus = applicationStatus;
    this.setDataSource();
  }  

  getApplicationStatuses(){
    return ['-Select Status-', ...(new Set(this.applications.filter(n => n.applicationStatusTxt).map(n => n.applicationStatusTxt) ?? [] ))];
  }

  filterData(data: any): boolean {
    var globalMatch = !this.filterStr;

    if (this.filterStr) {
      // search all text fields
      globalMatch = (
        data.detailRow ?
        typeof data.maApplication.applicationNum !== 'undefined' && data.maApplication.applicationNum !== null && data.maApplication.applicationNum.toLowerCase().includes(this.filterStr.toLowerCase()) ||
        typeof data.maApplication.firstNameTxt !== 'undefined' && data.maApplication.firstNameTxt !== null && data.maApplication.firstNameTxt.toLowerCase().includes(this.filterStr.toLowerCase()) ||
        typeof data.maApplication.lastNameTxt !== 'undefined' && data.maApplication.lastNameTxt !== null && data.maApplication.lastNameTxt.toLowerCase().includes(this.filterStr.toLowerCase()) ||
        typeof data.maApplication.birthDt !== 'undefined' && data.maApplication.birthDt !== null && data.maApplication.birthDt.toLowerCase().includes(this.filterStr.toLowerCase()) ||
        typeof data.maApplication.applicationStatusTxt !== 'undefined' && data.maApplication.applicationStatusTxt !== null && data.maApplication.applicationStatusTxt.toLowerCase().includes(this.filterStr.toLowerCase())
        :
        typeof data.applicationNum !== 'undefined' && data.applicationNum !== null && data.applicationNum.toLowerCase().includes(this.filterStr.toLowerCase()) ||
        typeof data.firstNameTxt !== 'undefined' && data.firstNameTxt !== null && data.firstNameTxt.toLowerCase().includes(this.filterStr.toLowerCase()) ||
        typeof data.lastNameTxt !== 'undefined' && data.lastNameTxt !== null && data.lastNameTxt.toLowerCase().includes(this.filterStr.toLowerCase()) ||
        typeof data.birthDt !== 'undefined' && data.birthDt !== null && data.birthDt.toLowerCase().includes(this.filterStr.toLowerCase()) ||
        typeof data.applicationStatusTxt !== 'undefined' && data.applicationStatusTxt !== null && data.applicationStatusTxt.toLowerCase().includes(this.filterStr.toLowerCase())
      );
    }

    // if none of fields contain the global search, then we just return, otherwise we look at column specific values
    if (!globalMatch) {
      return;
    }

    let allColumnsMatch = true;

    if(this.filteredValues.applicationStatus && this.filteredValues.applicationStatus != ('-Select Status-')){
      allColumnsMatch = (data.detailRow ?
          typeof data.maApplication.applicationStatusTxt !== 'undefined' && data.maApplication.applicationStatusTxt !== null && data.maApplication.applicationStatusTxt.toLowerCase().includes(this.filteredValues.applicationStatus.toLowerCase())
          :
          typeof data.applicationStatusTxt !== 'undefined' && data.applicationStatusTxt !== null && data.applicationStatusTxt.toLowerCase().includes(this.filteredValues.applicationStatus.toLowerCase())) 
        && allColumnsMatch;
    }

    return allColumnsMatch;
  }


/*   customFilterPredicate() {
    const myFilterPredicate = (data: any, filter: string): boolean => {
      var globalMatch = !this.filterStr;

      if (this.filterStr) {
        // search all text fields
        globalMatch = (
          data.detailRow ?
          typeof data.maApplication.applicationNum !== 'undefined' && data.maApplication.applicationNum !== null && data.maApplication.applicationNum.toLowerCase().includes(this.filterStr.toLowerCase()) ||
          typeof data.maApplication.firstNameTxt !== 'undefined' && data.maApplication.firstNameTxt !== null && data.maApplication.firstNameTxt.toLowerCase().includes(this.filterStr.toLowerCase()) ||
          typeof data.maApplication.lastNameTxt !== 'undefined' && data.maApplication.lastNameTxt !== null && data.maApplication.lastNameTxt.toLowerCase().includes(this.filterStr.toLowerCase()) ||
          typeof data.maApplication.birthDt !== 'undefined' && data.maApplication.birthDt !== null && data.maApplication.birthDt.toLowerCase().includes(this.filterStr.toLowerCase()) ||
          typeof data.maApplication.applicationStatusTxt !== 'undefined' && data.maApplication.applicationStatusTxt !== null && data.maApplication.applicationStatusTxt.toLowerCase().includes(this.filterStr.toLowerCase())
          :
          typeof data.applicationNum !== 'undefined' && data.applicationNum !== null && data.applicationNum.toLowerCase().includes(this.filterStr.toLowerCase()) ||
          typeof data.firstNameTxt !== 'undefined' && data.firstNameTxt !== null && data.firstNameTxt.toLowerCase().includes(this.filterStr.toLowerCase()) ||
          typeof data.lastNameTxt !== 'undefined' && data.lastNameTxt !== null && data.lastNameTxt.toLowerCase().includes(this.filterStr.toLowerCase()) ||
          typeof data.birthDt !== 'undefined' && data.birthDt !== null && data.birthDt.toLowerCase().includes(this.filterStr.toLowerCase()) ||
          typeof data.applicationStatusTxt !== 'undefined' && data.applicationStatusTxt !== null && data.applicationStatusTxt.toLowerCase().includes(this.filterStr.toLowerCase())
        );
      }

      // if none of fields contain the global search, then we just return, otherwise we look at column specific values
      if (!globalMatch) {
        return;
      }

      let filterValues = JSON.parse(filter);
      let allColumnsMatch = true;


      if(filterValues.applicationStatus && filterValues.applicationStatus != ('-Select Status-')){
        allColumnsMatch = (data.detailRow ?
            typeof data.maApplication.applicationStatusTxt !== 'undefined' && data.maApplication.applicationStatusTxt !== null && data.maApplication.applicationStatusTxt.toLowerCase().includes(filterValues.applicationStatus.toLowerCase())
            :
            typeof data.applicationStatusTxt !== 'undefined' && data.applicationStatusTxt !== null && data.applicationStatusTxt.toLowerCase().includes(filterValues.applicationStatus.toLowerCase())) 
          && allColumnsMatch;
      }

      return allColumnsMatch;
    }
    return myFilterPredicate;
  }
 */
}
  

