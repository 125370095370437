
import { NgModule } from '@angular/core';
import { MembersListComponent } from './members-list.component';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatTableModule } from '@angular/material/table';
import { CommonModule } from "@angular/common"
import { GroupleaderlistComponent } from '../groupleaderlist/groupleaderlist.component';
import { MatSortModule } from '@angular/material/sort';



@NgModule({
  declarations: [MembersListComponent],
  imports: [
    MatPaginatorModule,
    MatTableModule,
    MatSortModule,
    CommonModule
  ],
  exports: [
    MembersListComponent
  ]
})
export class MembersListModule { }
