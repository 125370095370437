export class AppConfig {
  public timeZoneOffset: number;

  constructor(
    public apiUrl_glp: string,
    public imageUrl: string,
    public dateFormatString: string,
    private numberFormatString: string
  ) {
    this.timeZoneOffset = new Date().getTimezoneOffset() / 60;
  }
}
