<h1 mat-dialog-title style="text-transform: none;">
    {{title}}
</h1>

<div mat-dialog-content>
<p style="white-space: pre-line;" [innerHTML]="message"></p>
</div>

<div *ngIf="input" mat-dialog-actions id="buttonsContainer">
    <div>
        <button class="buttonFont imageButton" (click)="onConfirm();">{{input}}</button>
    </div>
</div>

<div *ngIf="footermessage" mat-dialog-content>
    <h5 style="white-space: pre-line;">{{footermessage}}</h5>
</div>