import { ApprovalStatus } from "./ApprovalStatus";

export class GroupLeaderApprovalResponse extends ApprovalStatus {

  constructor() {
    super();
  }

  public accountType: string;

}
