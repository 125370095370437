import { EmailDialogComponent } from './../email-dialog/email-dialog.component';
import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MemberDataService } from '../../services/member-data.service';
import { ActivityLogService } from '../../../../services/activity-log.service';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { AlertDialogComponent } from 'src/app/glp-common/components/alert-dialog/alert-dialog.component';
import { ConfirmDialogComponent } from 'src/app/glp-common/components/confirm-dialog/confirm-dialog.component';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { ActivityType } from 'src/app/models/ActivityType';
import { LoggingService } from 'src/app/utilities/logging.service';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { MemberInformationComponent } from '../member-information/member-information.component';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-member-detail',
  templateUrl: './member-detail.component.html',
  styleUrls: ['./member-detail.component.scss'],
})
export class MemberDetailComponent implements OnInit {
  memberInfo: any;
  user: string;
  userEmail: string;
  groupId: string;
  isLoading: boolean = false;
  selectedMemberId: number;
  dataSource = new MatTableDataSource<any>();
  idType: string;
  coverageType: string;

  displayedColumns: string[] = ['memberId', 'gender', 'memberName', 'relation', 'dateOfBirth'];

  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dataService: MemberDataService,
    private logService: ActivityLogService,
    public oidcSecurityService: OidcSecurityService,
    private userService: UserService,
    private dialog: MatDialog,
    private loggingService: LoggingService
  ) {
    this.memberInfo = data;
  }

  ngOnInit(): void {
    const errorDialogConfig = new MatDialogConfig();

    this.oidcSecurityService.getPayloadFromIdToken().subscribe((account)=>{
      if (account) {
        this.user = `${account.extension_FirstName} ${account.extension_LastName}`;
        if (account.emails?.length > 0) {
          this.userEmail = account.emails[0];
        }
        this.groupId = account.extension_AccountNumber;

        if(this.groupId == null || this.groupId == 'undefined'){
            this.groupId = this.dataService.selectedGroupID.toString();
        }

      //default idType is groupId
      if(this.dataService.idType == undefined || this.dataService.idType == null){
        this.idType = 'groupId';
      }
      else{
        this.idType = this.dataService.idType;
      }
        const subscriptionId = this.memberInfo.memberId.substring(0,9);
        const groupId = this.memberInfo.accountId;

        sessionStorage.setItem('groupId', groupId);

        if(this.idType == 'individualId'){
          this.coverageType = this.dataService.productType;
        }

        this.dataService.getSubscriberDetails(subscriptionId, groupId, this.userEmail, this.idType, this.coverageType).subscribe((list) =>
        {
          let array = [];
          if (list !== null && list.length > 0)
          {
            array = list.map((item) => {
              return {
                $key: item.memberId,
                ...item,
              };
            });
          }

          this.dataSource = new MatTableDataSource(array);
          this.dataSource.filterPredicate = (data, filter) => {
            return this.displayedColumns.some((ele) => {
              return data[ele].toLowerCase().indexOf(filter) != -1;
            });
          };
          this.dataSource.sort = this.sort;
          this.dataSource.sortingDataAccessor = (item, property) => {
            switch (property) {
              case 'membername': return new Date(item.membername);
              case 'relation': return new Date(item.relation);
              case 'dateOfBirth': return new Date(item.dateOfBirth);
              default: return item[property];
            }
          };
          this.dataSource.paginator = this.paginator;
        },
        (error) => {
          if(error.status == 400){
            errorDialogConfig.data = {
              title: 'Invalid Member Information provided',
              message: 'The Member Information provided is either not associated with this broker or we are having trouble finding it. Please try again later.',
              footermessage: 'Close the Browser to Exit',
            };
            var err = {
              name: 'Invalid Member Information provided',
              message: 'Invalid Member Information provided = ' + account.extension_AccountNumber,
              stack: 'Invalid Member Information provided',
            };
            this.loggingService.logException(err, 3);
  
            this.dialog.open(AlertDialogComponent, errorDialogConfig);
            this.dialog.afterAllClosed.subscribe((_) => this.oidcSecurityService.logoff());
          }
          if(error.status == 500){
            errorDialogConfig.data = {
              title: 'Error retrieving member',
              message: 'We are currently having trouble retrieving this member. Please try again later.',
              footermessage: 'Close the Browser to Exit',
            };
            var err = {
              name: 'Error retrieving member',
              message: 'Error retrieving member = ' + account.extension_AccountNumber,
              stack: 'Error retrieving member',
            };
            this.loggingService.logException(err, 3);
  
            this.dialog.open(AlertDialogComponent, errorDialogConfig);
            this.dialog.afterAllClosed.subscribe((_) => this.oidcSecurityService.logoff());
          }
        }
        );
      }
    });

  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
  }

  orderCard() {
    const confirmDialogConfig = new MatDialogConfig();
    confirmDialogConfig.data = {
      title: 'Confirm Order ID Card',
      message:
        "ID cards will be mailed to the address displayed on the member profile.\n If the member's address has changed, please update your eligibility files prior to ordering.",
    };
    const confirmDialogRef = this.dialog.open(
      ConfirmDialogComponent,
      confirmDialogConfig
    );
    confirmDialogRef.afterClosed().subscribe((dialogResult) => {
      if (dialogResult) {
        window.dataLayer.push({'event': 'broker_click', 'click_location': 'Member Profile Actions', 'click_action': 'Order ID Card - Accept - Step 3'})
        this.isLoading = true;
        this.dataService
          .orderIdCard(
            this.memberInfo.memberId,
            this.memberInfo.name,
            this.user,
            this.groupId,
            this.userEmail,
            this.memberInfo.planVersionEffectiveThruDt,
            this.idType
          )
          .subscribe(
            (data) => {
              this.isLoading = false;
              const alertSuccessDialogConfig = new MatDialogConfig();
              alertSuccessDialogConfig.data = {
                title: 'Result Order ID Card',
                message: 'ID card requested successfully',
                input: 'Accept',
              };
              this.dialog.open(AlertDialogComponent, alertSuccessDialogConfig);
            },
            (err) => {
              this.isLoading = false;
              this.loggingService.logException(err, 3);
              let friendlyError = 'An error occurred while requesting ID card';
              // if (
              //   err.error &&
              //   err.error.Message &&
              //   err.error.Message.includes('Epub Error:')
              // ) {
              //   friendlyError = err.error.Message.replace('Epub Error:', '');
              // }
              const errorDialogConfig = new MatDialogConfig();
              errorDialogConfig.data = {
                title: 'Result Order ID Card',
                message: friendlyError,
                input: 'Accept',
              };
              this.dialog.open(AlertDialogComponent, errorDialogConfig);
            }
          );
      }else{
        window.dataLayer.push({'event': 'broker_click', 'click_location': 'Member Profile Actions', 'click_action': 'Order ID Card - Cancel - Step 3'})
      }
    });
  }

  emailCard() {
    this.isLoading = true;
    this.logService
      .log(
        this.userEmail,
        this.userService.isBrokerUser(),
        ActivityType.SuccessOrderCard,
        this.memberInfo.memberId
      )
      .subscribe(
        (data) => {},
        (err) => {
          this.loggingService.logException(err, 4);
        }
      );
    const config = new MatDialogConfig();
    config.minHeight = 292;
    config.minWidth = 576;
    const emailDialogRef = this.dialog.open(EmailDialogComponent, config);
    emailDialogRef.afterClosed().subscribe((dialogResult) => {
      if (dialogResult && dialogResult.email) {
        window.dataLayer.push({'event': 'broker_click', 'click_location': 'Member Profile Actions', 'click_action': 'Email ID Card - Accept - Step 3'})
        this.isLoading = true;
        this.dataService
          .emailIdCard(
            this.memberInfo.memberId,
            dialogResult.email,
            this.userEmail,
            this.idType
          )
          .subscribe(
            (res) => {
              this.isLoading = false;
              if (res) {
                const alertSuccessDialogConfig = new MatDialogConfig();
                alertSuccessDialogConfig.data = {
                  title: 'Result Email ID Card',
                  message: 'ID card emailed successfully',
                  input: 'Accept',
                };
                this.dialog.open(
                  AlertDialogComponent,
                  alertSuccessDialogConfig
                );
              } else {
                this.logService
                  .log(
                    this.userEmail,
                    this.userService.isBrokerUser(),
                    ActivityType.NoDownloadCardAvailable,
                    this.memberInfo.memberId
                  )
                  .subscribe(
                    (data) => {},
                    (err) => {
                      this.loggingService.logException(err, 3);
                    }
                  );
                const alertSuccessDialogConfig = new MatDialogConfig();
                alertSuccessDialogConfig.data = {
                  title: 'ID Card Error',
                  message:
                    'The ID card you have selected is not available to email at this time.  Please contact your account team for further assistance.',
                  input: 'Accept',
                };
                this.dialog.open(
                  AlertDialogComponent,
                  alertSuccessDialogConfig
                );
              }
            },
            (err) => {
              this.isLoading = false;
              this.loggingService.logException(err, 3);
              // console.log(err);
              let friendlyError =
                'The ID card you have selected is not available to email at this time.  Please contact your account team for further assistance.';
              const errorDialogConfig = new MatDialogConfig();
              errorDialogConfig.data = {
                title: 'ID Card Error',
                message: friendlyError,
                input: 'Accept',
              };
              this.dialog.open(AlertDialogComponent, errorDialogConfig);
            }
          );
      } else {
        window.dataLayer.push({'event': 'broker_click', 'click_location': 'Member Profile Actions', 'click_action': 'Email ID Card - Cancel - Step 3'})
        this.isLoading = false;
      }
    });
  }

  downloadCard() {
    this.isLoading = true;
    this.logService
      .log(
        this.userEmail,
        this.userService.isBrokerUser(),
        ActivityType.DownloadIDCard,
        this.memberInfo.memberId
      )
      .subscribe(
        (data) => {},
        (err) => {
          this.loggingService.logException(err, 4);
        }
      );
    this.dataService
      .downloadIdCard(this.memberInfo.memberId, this.userEmail, this.idType)
      .subscribe(
        (data) => {
          this.isLoading = false;
          if (data) {
            const element = document.createElement('a');
            document.body.appendChild(element);

            element.href = URL.createObjectURL(data.imageFront);
            element.download = 'front_' + this.memberInfo.memberId + '.png';
            element.click();

            element.href = URL.createObjectURL(data.imageBack);
            element.download = element.download =
              'back_' + this.memberInfo.memberId + '.png';
            element.click();

            element.remove();
          } else {
            this.logService
              .log(
                this.userEmail,
                this.userService.isBrokerUser(),
                ActivityType.NoDownloadCardAvailable,
                this.memberInfo.memberId
              )
              .subscribe(
                (data) => {},
                (err) => {
                  this.loggingService.logException(err, 4);
                }
              );

            let friendlyError =
              'The ID card you have selected is not available to view or download at this time. \n Please contact your account team for further assistance.';

            const errorDialogConfig = new MatDialogConfig();
            errorDialogConfig.data = {
              title: 'Result Download Document',
              message: friendlyError,
              input: 'Accept',
            };
            this.dialog.open(AlertDialogComponent, errorDialogConfig);
          }
        },
        (err) => {
          this.isLoading = false;
          this.loggingService.logException(err, 4);
          // console.log(err);
          let friendlyError =
            'An error occurred while downloading the document';

          const errorDialogConfig = new MatDialogConfig();
          errorDialogConfig.data = {
            title: 'Result Download Document',
            message: friendlyError,
            input: 'Accept',
          };
          this.dialog.open(AlertDialogComponent, errorDialogConfig);
        }
      );
  }

  findDoctor() {
    this.dataService
      .getDoctorLink(
        this.memberInfo.accountId,
        this.memberInfo.uniquePlanId,
        this.userEmail,
        this.memberInfo.memberId,
        this.idType
      )
      .subscribe(
        (data) => {
          if (data.deepLink.startsWith('http')) {
            const link = document.createElement('a');
            link.setAttribute('href', data.deepLink);
            link.setAttribute('target', '_blank');
            document.body.appendChild(link);
            link.click();
            link.remove();
          } else {
            const errorDialogConfig = new MatDialogConfig();
            errorDialogConfig.data = {
              title: 'Network Details Unavailable',
              message: `Network details unavailable at the moment. Please select your network manually on this link. \n <a href=\"https://bcbsneweb.healthsparq.com/healthsparq/public/#/one/state=ne&postalCode=&country=&insurerCode=BCBSNE_I&brandCode=BCBSNE\" target="_blank">Find a Doctor</a>`,
              input: 'Close',
            };
            this.dialog.open(AlertDialogComponent, errorDialogConfig);
          }
        },
        (err) => {
          // console.log(err);
          let friendlyError =
            'An error occurred while getting find doctor link';
          this.loggingService.logException(err, 4);
          const errorDialogConfig = new MatDialogConfig();
          errorDialogConfig.data = {
            title: 'Result Find Doctor',
            message: friendlyError,
            input: 'Accept',
          };
          this.dialog.open(AlertDialogComponent, errorDialogConfig);
        }
      );
  }

  openDetails(row: any) {
    console.log('row = ' + JSON.stringify(row));

    const dialogRef = this.dialog.open(MemberInformationComponent, {
      width: '90%',
      //height: '75%',
      minHeight: '300px' ,
      autoFocus: false,
      disableClose: true,
      data: { record: row }
    });
  }

  handleGtagClick(click_location: string, click_action: string): void {
    window.gTagPush(click_location, click_action)
  }
}
