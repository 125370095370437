<div class="bookOfBusinessBackground">
  <h6 style="color: white;" id="MemberHeading">Application Status</h6>
</div>

<div id="full-container">
  <div></div>
  <div class="grid-container" style="overflow: auto;">
    <div class="mainSearchBar">
      <div id="searchContainer">
        <div style="display: flex; height: 60px; padding-top: 8px ; width: 110%">
          <input type="text" class="input-search" #filter (keyup)="updateSearchText($event.target.value);" placeholder="Type to filter" #searchInput>
          <button class="searchButton" (click)="setDataSource();" onclick="gtag('event', 'SearchGroup', {'event_name': 'Search Group','screen_name': 'Group List'});">Search</button>
        </div>
      </div>       

      <div class="filterSearchDiv">
        <label style="color: black;"for="standard-select">Application Status </label>
        <div class="select">
          <select id="standard-select" (change)="updateApplicationStatusFilter($event.target.value)" >
            <option *ngFor="let item of getApplicationStatuses()" [value]="item">{{ item }}</option>
          </select>
          <span class="focus"></span>
        </div>
      </div>

    </div>
    
    <mat-table class="mat-elevation-z8" [dataSource]="dataSource" matSort matSortActive="applicationStatus" matSortDirection="asc" (matSortChange)="sortData($event)" matSortDisableClear>

      <ng-container matColumnDef="applicationNum">
          <mat-header-cell mat-sort-header *matHeaderCellDef style="max-width: 21%;"> Application Number </mat-header-cell>
          <mat-cell *matCellDef="let application" style="max-width: 21%;"> {{application.applicationNum}} </mat-cell>
      </ng-container>
    
      <ng-container matColumnDef="firstNameTxt">
          <mat-header-cell mat-sort-header *matHeaderCellDef style="max-width: 21%;"> First Name </mat-header-cell>
          <mat-cell *matCellDef="let application" style="max-width: 21%;"> {{application.firstNameTxt}} </mat-cell>
      </ng-container>
    
      <ng-container matColumnDef="lastNameTxt">
          <mat-header-cell mat-sort-header *matHeaderCellDef style="max-width: 21%;"> Last Name </mat-header-cell>
          <mat-cell *matCellDef="let application" style="max-width: 21%;"> {{application.lastNameTxt}} </mat-cell>
      </ng-container>
    
      <ng-container matColumnDef="birthDt">
          <mat-header-cell mat-sort-header *matHeaderCellDef style="max-width: 16%;"> Date of Birth </mat-header-cell>
          <mat-cell *matCellDef="let application" style="max-width: 16%;"> {{ application.birthDt }} </mat-cell>
      </ng-container>
    
      <ng-container matColumnDef="applicationStatusTxt">
          <mat-header-cell mat-sort-header *matHeaderCellDef style="max-width: 16%;"> Application Status </mat-header-cell>
          <mat-cell *matCellDef="let application" style="max-width: 16%;"> {{application.applicationStatusTxt}} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="icon">
        <mat-header-cell *matHeaderCellDef style="max-width: 5%;"></mat-header-cell>
        <mat-cell *matCellDef="let application" style="max-width: 5%;">
          <i *ngIf="!expandedApplication || (expandedApplication && expandedApplication.applicationNum != application.applicationNum)" class="arrow down"></i>
          <i *ngIf="expandedApplication && expandedApplication.applicationNum == application.applicationNum" class="arrow up"></i>
        </mat-cell>
      </ng-container>
    
      <ng-container matColumnDef="expandedDetail">
        <mat-cell *matCellDef="let detail" style="min-width: 100%; padding-left: 0px !important; padding-right: 0px !important;"> 
          <div class="detailContainer">
            <div style="width: 21%; border-bottom: 0px">
              <div class="detailLabel mdc-data-table__header-cell" style="padding-left: 24px !important;">Broker ID:</div> 
              <div class="detailText mat-mdc-cell mdc-data-table__cell" style="padding-left: 24px !important;">{{detail.maApplication.agentId}}</div>
            </div> 
            <div style="width: 21%; border-bottom: 0px">
              <div class="detailLabel mdc-data-table__header-cell">Application Date:</div> 
              <div class="detailText mat-mdc-cell mdc-data-table__cell">{{detail.maApplication.receiptDt}}</div> 
            </div>
            <div style="width: 21%; border-bottom: 0px;">
              <div class="detailLabel mdc-data-table__header-cell">Plan Information:</div> 
              <div class="detailText mat-mdc-cell mdc-data-table__cell">{{detail.maApplication.enrollingContract + '-' + detail.maApplication.enrollingPbp}}</div>
            </div>
            <div style="width: 16%; border-bottom: 0px">
              <div class="detailLabel mdc-data-table__header-cell">Coverage Date:</div> 
              <div class="detailText mat-mdc-cell mdc-data-table__cell">{{detail.maApplication.requestedDtOfCoverage}}</div>
            </div> 
            <div style="width: 16%; border-bottom: 0px">
              <div class="detailLabel mdc-data-table__header-cell">Phone Number:</div> 
              <div class="detailText mat-mdc-cell mdc-data-table__cell">{{detail.maApplication.primaryHomePhone}}</div>
            </div> 
          </div>
        </mat-cell>

      </ng-container>


    
      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns;"
              matRipple 
              class="element-row" 
              [class.expanded]="expandedApplication == row"
              [class.selectedRow]="expandedApplication == row"
              (click)="expandedApplication = (row == expandedApplication ? null : row)"></mat-row>
      <mat-row *matRowDef="let row; columns: ['expandedDetail']; when: isExpansionDetailRow"
              [@detailExpand]="row.maApplication == expandedApplication ? 'expanded' : 'collapsed'"
              style="overflow: hidden; background-color: #e1e1e1; cursor: default"> 
      </mat-row>

    </mat-table>

    <mat-paginator [pageSize]="10"
    (page)="handlePagination($event)"
    [length]="this.dataCount"
    [pageSizeOptions]="[10]" [showFirstLastButtons]="true"
    [hidePageSize]="true"></mat-paginator>

  </div>
  <div></div>
</div> 
