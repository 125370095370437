import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReportsComponent } from './reports.component';
import { ReportsListComponent } from './components/reports-list/reports-list.component';
import { MaterialModule } from '../../material/material.module';
import { AlertDialogComponent } from 'src/app/glp-common/components/alert-dialog/alert-dialog.component';
import { ConfirmDialogComponent } from 'src/app/glp-common/components/confirm-dialog/confirm-dialog.component';

@NgModule({
  declarations: [ReportsComponent, ReportsListComponent],
  imports: [
    CommonModule,
    MaterialModule
  ],
  entryComponents:[AlertDialogComponent, ConfirmDialogComponent]
})
export class ReportsModule { }
