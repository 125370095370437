export enum ActivityType {
    Generic,
    SignUp,
    SignUpUpdate,
    ResetPassword,
    SignIn,
    SignOut,
    RequestOrderCard,
    SuccessOrderCard,
    RequestOrderCardAlternate,
    DownloadIDCard,
    FindDoctor,
    DownloadPlanDocument,
    DownloadFormDocument,
    DownloadAccountDocument,
    DownloadBillingDocument,
    Help,
    Home,
    OpenNotificationsSection,
    DownloadAccessForm,
    SendIDCard,
    EmailAccessForm,
    ViewNotificationDetail,
    NoDownloadCardAvailable,
    AlternateEmailCard,
    ExpandHelp,
    EmailCard,
    SendFormDocument,     
    PlaceHolder1,
    PlaceHolder2,
    RejectedLogin,
    ClickFaqLink,
    ClickFaqGuide,
    ClickFaqVideo
  }
