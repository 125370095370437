import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Observable, from, of } from 'rxjs';
import { mergeMap, tap } from 'rxjs/operators';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { UserService } from '../services/user.service';
import { MemberDataService } from '../content/home/services/member-data.service';
/*
import { JwtHelperService } from '@auth0/angular-jwt';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { AlertDialogComponent } from 'src/app/glp-common/components/alert-dialog/alert-dialog.component';
import { LoggingService } from 'src/app/utilities/logging.service';
*/

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  constructor(public oidcSecurityService: OidcSecurityService) { }
  /*
  constructor(public oidcSecurityService: OidcSecurityService,
              private dialog: MatDialog,
              private loggingService: LoggingService) {}  
              private loggedInStatus = false;
  */

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    this.oidcSecurityService.getAccessToken().subscribe((token) => {


      if (!token) {
        return next.handle(req);
      }

      req = req.clone({
        headers: req.headers.set('Authorization', 'Bearer ' + token),
      });

      return next.handle(req);
    });
    return next.handle(req);
  }

}

@Injectable()
export class CacheInterceptor implements HttpInterceptor {
  constructor(
    public oidcSecurityService: OidcSecurityService,     
    private userService: UserService,
    private memberDataService: MemberDataService
    ) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {


      let setParamsObject = {};
      if(this.userService.userEmail){
        setParamsObject["email"] = this.userService.userEmail;
      }
      if(this.memberDataService.selectedGroupID && !req.params.get('groupId') && !req.params.get('groupid') && !req.params.get('groupID')){
        setParamsObject["groupId"] = this.memberDataService.selectedGroupID.toString();
      }
      setParamsObject["isBroker"] = this.userService.isBrokerUser();
      setParamsObject["isGroupLeader"] = this.userService.isGroupLeaderUser();

      req = req.clone({
        setParams: setParamsObject
      });

    req = req.clone({
      headers: req.headers
        .set('Cache-Control', 'no-cache, no-store, must-revalidate')
        .set('Cache-directive', 'no-cache')
        .set('Pragma-directive', 'no-cache')
        .set('Pragma', 'no-cache')
        .set('Expires', '0')
    });

    return next.handle(req);
  }

}
