import { EmailDialogComponent } from './../content/home/components/email-dialog/email-dialog.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AlertDialogComponent } from './components/alert-dialog/alert-dialog.component';
import { ConfirmDialogComponent } from './components/confirm-dialog/confirm-dialog.component';



@NgModule({
  declarations: [AlertDialogComponent, ConfirmDialogComponent],
  imports: [
    CommonModule
  ],
  exports: [
    AlertDialogComponent,
    ConfirmDialogComponent
    
  ]
})
export class GlpCommonModule { }
