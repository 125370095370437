import { Component } from '@angular/core';
import { UserService } from '../../../app/services/user.service';
import { MemberDataService } from 'src/app/content/home/services/member-data.service';

@Component({
  selector: 'app-broker',
  templateUrl: './broker.component.html',
  styleUrls: ['./broker.component.scss']
})


export class BrokerComponent {
  user: string;
  svc: UserService;
  group: boolean = true;
  indiviual: boolean = true;
  subscribers: string[] = ['Group', 'Indiviual'];
  selectedSubscriber: string | null = null;

  constructor(
    private dataService: MemberDataService
  ) {}

  ngOnInit() {
    this.dataService.selectedSubscriber.subscribe(subscriber => {
      this.selectedSubscriber = subscriber || 'Group';
      this.updatePage(); 
    });

    this.dataService.currentMessageSubscriber.subscribe((userAdded: any) => {
      if (userAdded) {
        this.user = this.dataService.userName;
      }
    })

  }

  getUser(): void {
    this.user = this.svc.getUser().userId;
    console.log(this.user);
  }

  onChange(event) {
    this.selectedSubscriber = (event.target as HTMLSelectElement).value;
    this.dataService.setSelectedSubscriber(this.selectedSubscriber);
    this.updatePage();
  }

  updatePage(): void {
    if (this.selectedSubscriber === 'Group') 
    {
      this.group = true;
      this.indiviual = false;
    } else if (this.selectedSubscriber === 'Individual') 
    {
     this.indiviual = true;
     this.group = false;
    } 
  }

}
