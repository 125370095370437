import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { AppConfigService } from '../../../app.config.service';
import { ReportInfo } from '../models/ReportInfo';
import { delay,map } from 'rxjs/operators';
//import { Byte } from '@angular/compiler/src/util';
import { FIReportData } from '../models/FIReportData';

@Injectable({
  providedIn: 'root'
})
export class ReportDataService {

  private settingsUrl: string;
  private reportUrl: string;

  constructor(private http: HttpClient,
    private appConfigService: AppConfigService) {

  }


  getReportList(groupId:string, accountType:string, idType:string, subscriberId:string): Observable<ReportInfo[]> {
    console.log(`${this.appConfigService.AppConfig.apiUrl_glp}/ListReports`);
    return this.http.get<ReportInfo[]>(`${this.appConfigService.AppConfig.apiUrl_glp}/ListReports`, {
      params: new HttpParams()
          .set('groupid', groupId.toString())
          .set('accountType', accountType.toUpperCase())
          .set('type', idType)
          .set('subscriberId', subscriberId)
  });
  }
  

  getASOReportFile(userEmail: string, category:string, fileName:string, accountType:string, groupId: string, idType:string): Observable<any> {
    return this.http.get(`${this.appConfigService.AppConfig.apiUrl_glp}/GetReport`, {
      observe: 'response',
      responseType: 'text',
      params: new HttpParams()
          .set('userEmail', userEmail)
          .set('Filetype', category.toLowerCase())
          .set('fileName', fileName)
          .set('accountType', accountType.toUpperCase())
          .set('groupId', groupId)
          .set('type', idType)
    });
  }

  getFIReportFile(userEmail: string, category:string, fileName:string, accountType:string, groupId: string, idType:string, subscriberId: string): Observable<any> {
    return this.http.get<FIReportData[]>(`${this.appConfigService.AppConfig.apiUrl_glp}/GetReport`, {
      //observe: 'response',
      //responseType: 'text',
      params: new HttpParams()
          .set('userEmail', userEmail)
          .set('type', category.toLowerCase())
          .set('fileName', fileName)
          .set('accountType', accountType.toUpperCase())
          .set('groupId', groupId)
          .set('subscriberId',subscriberId)
          .set('type', idType)

    })
    /*
     .pipe(map(res=>{
       var jsonRes = JSON.parse(res);
       if(!jsonRes || jsonRes.length == 0){
         return null;
       }
       const contentType = 'application/pdf';
       let parsed = jsonRes[0];
       let data = {
         imageReport: this.b64toBlob(parsed.ImageData, contentType),
       }
       console.log("imageReport = " + res);
       return data;
     }));
     */
  }

  /*
  private b64toBlob(b64Data, contentType='', sliceSize=512) {
    const byteCharacters = atob(b64Data);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    const blob = new Blob(byteArrays, {type: contentType});
    return blob;
  }
  */

}
