import {
  AfterViewInit,
  Component,
  ElementRef,
  OnInit,
  ViewChild,
} from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ReportDataService } from '../../services/report-data.service';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { AlertDialogComponent } from 'src/app/glp-common/components/alert-dialog/alert-dialog.component';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { ActivityLogService } from 'src/app/services/activity-log.service';
import { ActivityType } from 'src/app/models/ActivityType';
import { LoggingService } from 'src/app/utilities/logging.service';
import { MemberDataService } from 'src/app/content/home/services/member-data.service';
import { UserService } from 'src/app/services/user.service';
//import { Byte } from '@angular/compiler/src/util';
@Component({
  selector: 'app-reports-list',
  templateUrl: './reports-list.component.html',
  styleUrls: ['./reports-list.component.scss'],
})
export class ReportsListComponent implements OnInit, AfterViewInit {
  groupId: string;
  accountType: string;
  imageData: Blob;
  user: string;
  userEmail: string;
  filterStr: string = '';
  dataSource: MatTableDataSource<any>;
  displayedColumns: string[] = ['category', 'name', 'downloadLink'];
  enableSearch: boolean;
  idType: string;
  subscriberId: string;

  @ViewChild(MatPaginator) paginator: MatPaginator;

  @ViewChild(MatSort) sort: MatSort;

  @ViewChild('searchInput') searchInput: ElementRef;

  constructor(
    private dataService: ReportDataService,
    private dialog: MatDialog,
    private logService: ActivityLogService,
    private userService: UserService,
    public oidcSecurityService: OidcSecurityService,
    private loggingService: LoggingService,
    private memberData: MemberDataService
  ) {}

  ngOnInit() {
    this.oidcSecurityService.getPayloadFromIdToken().subscribe((account)=>{
    if (account) {
      this.groupId = this.memberData.selectedGroupID || account.extension_AccountNumber;
      if ((typeof this.groupId == 'undefined' || this.groupId.split(",").length > 1) && this.memberData.idType != 'individualId') {
        this.groupId = this.memberData.selectedGroupID.toString();
      }
      if(account.emails?.length>0){
        this.userEmail = account.emails[0];
      }
      this.accountType = sessionStorage.getItem('accountType') ?? "";

      //console.log("this.accountType = " + this.accountType);

        //default idType is groupId
        if(this.memberData.idType == undefined || this.memberData.idType == null){
          this.idType = 'groupId';
        }
        else{
          this.idType = this.memberData.idType;
        }

        if(this.memberData.selctedSubscriber !=undefined || this.memberData.selctedSubscriber == null){
          this.subscriberId = this.memberData.selctedSubscriber;
        }      

      if(this.idType == "individualId" && this.groupId == undefined)
        this.groupId = "";

      this.dataService.getReportList(this.groupId, this.accountType, this.idType, this.subscriberId).subscribe((list) => {
        let array = list.map((item) => {
          return {
            $key: item.name,
            ...item,
          };
        });
        this.dataSource = new MatTableDataSource(array);
        this.enableSearch = true;
        this.dataSource.filterPredicate = (data, filter) => {
          return this.displayedColumns
            .filter((dc) => dc != 'downloadLink')
            .some((ele) => {
              return data[ele].toLowerCase().indexOf(filter) != -1;
            });
        };
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;
      });

    }
  });
  }

  ngAfterViewInit() {}

  applyFilter(filterValue: string) {
      this.filterStr = filterValue.trim();
      if(this.dataSource)
      {
      if (filterValue.trim() != "")
      {
        filterValue = filterValue.trim(); // Remove whitespace
        filterValue = filterValue.toLowerCase(); // Datasource defaults to lowercase matches
        this.dataSource.filterPredicate = (data: any, filter: string) => {

            var c1 = (typeof data.name !== 'undefined' && data.name !== null && data.name.toLowerCase().includes(filter));
            return c1;
        };
        this.dataSource.filter = filterValue;
      }

        this.dataSource.filter = filterValue;
      }
  }

  clickFilter() {
    this.dataSource.filter = this.searchInput.nativeElement.value.trim().toLowerCase();
  }

  downloadFile(category: string, name: string, imageData: string) {
    const activity =
      category === 'Accounting'
        ? ActivityType.DownloadAccountDocument
        : ActivityType.DownloadBillingDocument;

    this.logService.log(this.userEmail, this.userService.isBrokerUser(),ActivityType.DownloadBillingDocument, name).subscribe(
      (data) => {},
      (err) => {
        this.loggingService.logException(err, 3);
      }
    );

    if (!name.includes("-PremiumBilling-") && !name.includes("Statement-Billing"))
    {
      this.dataService.getASOReportFile(this.userEmail, category, name, this.accountType, this.groupId, this.idType).subscribe(
       (data) => {
          // console.log('downloadResource = ', data.body);
          this.downloadResource(data.body, name);
        },
        (err) => {
          // console.log(err);
          let friendlyError = 'An error occurred while downloading the report';
          this.loggingService.logException(err, 3);
          const errorDialogConfig = new MatDialogConfig();
          errorDialogConfig.data = {
            title: 'Result Download Report',
            message: friendlyError,
            input: 'Accept',
          };
          this.dialog.open(AlertDialogComponent, errorDialogConfig);
        }
      );
    }

    if (name.includes("-PremiumBilling-") || name.includes("Statement-Billing"))
    {
      this.dataService.getFIReportFile(this.userEmail, category, name, this.accountType, this.groupId, this.idType, this.subscriberId).subscribe(
        (data) => {

          let reportData = data[0];
          let dataType = data[1];
          let fileName = " ";

          const linkSource = 'data:' + dataType + ';base64,' + reportData;
          const downloadLink = document.createElement("a");
          if(dataType === "application/pdf"){
            fileName = name + ".pdf";
          }
          else if(dataType === "text/plain"){
            fileName = name + ".txt";
          }
          // BUGBUG - Converting streams with the "application/octet-stream" type to Excel files is error prone.  This is a temporary
          //           work around while we ask the the Macess service team to ensure all documents have the right MIME type.
          else if((dataType === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet") ||
                  (dataType === "application/octet-stream"))
          {
            fileName = name + ".xlsx";
          }
          else if(dataType === "image/tiff" || dataType === "image/png" || dataType === "image/jpeg"){
            fileName = name + ".tif";
          }
          else {
            let message =
              "Warning: A report file had an unsupported data type (MIME type).  This means it will be hard for the user to " +
              "open the file because it will not have a file extension.  The web site should be updated to support this file " +
              "type.";

            let properties = {
                reportFileName: name,
                reportFileMimeType: dataType,
              };

            this.loggingService.logTrace(message, properties);

            fileName = name;
          }

          downloadLink.href = linkSource;
          downloadLink.download = fileName;
          downloadLink.click();
        },
        (err) => {
          // console.log(err);
          let friendlyError = 'An error occurred while downloading the report';
          this.loggingService.logException(err, 3);
          const errorDialogConfig = new MatDialogConfig();
          errorDialogConfig.data = {
            title: 'Result Download Report',
            message: friendlyError,
            input: 'Accept',
          };
          this.dialog.open(AlertDialogComponent, errorDialogConfig);
        }
      );
    }
  }

  getReportCategory(category: string){
    let categoryName = '';
    switch(category){
      case 'TempIdCard':
        categoryName = 'Temporary Id Card'
        break;

      default:
        categoryName = 'Accounting';
    }

    return categoryName;
  }

  private sanitizeFilename(filename) {
    // Define a regular expression to match safe filename characters
    const safeCharactersRegex = /^[a-zA-Z0-9_. -]+$/;

    // Return true if the filename contains only safe characters, false otherwise
    return safeCharactersRegex.test(filename);
  }

  private downloadResource(url, filename) {
    // If no filename is set, use filename from URL
    if (!filename) {
      const match = url.match(/\/([^/#?]+)[^/]*$/);
      if (match) {
        filename = match[1];
      } else {
        console.error("Invalid URL");
        return;
      }
    } else if (!this.sanitizeFilename(filename)) {
      console.error("Invalid filename");
      return;
    }

    fetch(url, {
      headers: new Headers({
        Origin: window.location.origin,
      }),
      mode: 'cors',
    })
      .then((response) => response.blob())
      .then((blob) => this.forceDownload(blob, filename))
      .catch((e) => console.error(e));
  }

  private forceDownload(blob, filename) {
    // Create an anchor element
    const anchor = document.createElement('a');
    anchor.href = window.URL.createObjectURL(blob);
    anchor.setAttribute('download', filename);

    // Programmatically trigger a click event on the anchor
    anchor.click();

    // Clean up
    window.URL.revokeObjectURL(anchor.href);
  }

  ts_map = {
    "Report Category":"descending",
    "Report Name":"descending",
  }

 toggleSortingOrder(key: string) {
    if (key in this.ts_map) {
        for (const k in this.ts_map) {
          if (k !== key) {
              this.ts_map[k] = "descending";
          }
        }
        if (this.ts_map[key] === "descending") {
            window.dataLayer.push({'event': 'broker_click', 'click_location':'Reports', 'click_action': `Table Header - ${key} - ascending`});
            this.ts_map[key] = "ascending";
        } else {
            window.dataLayer.push({'event': 'broker_click', 'click_location':'Reports', 'click_action': `Table Header - ${key} - descending`});
            this.ts_map[key] = "descending";
        }
    } else {
        console.log(`${key} is not a valid key in the map.`);
    }
  }

  brokerClickPagination(event: any){
    if(event.srcElement.parentElement.ariaLabel != null){
      window.dataLayer.push({'event': 'broker_click', 'click_location': 'Reports', 'click_action': `Pagination - ${event.srcElement.parentElement.ariaLabel}`})
    }
   }

  pageSizeChange(newPageSize: number) {
    window.dataLayer.push({'event': 'broker_click', 'click_location':'Reports', 'click_action': `Filter - ${newPageSize} items per page`});
  }
  
  handleGtagClick(click_location: string, click_action: string): void {
    window.gTagPush(click_location, click_action)
  }
}
