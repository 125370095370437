import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { AppConfigService } from '../../../app.config.service';
import { FormInfo } from '../models/FormInfo';
import { delay } from 'rxjs/operators';
import { SendFormEmailRequest } from '../models/SendFormEmailRequest';

@Injectable({
  providedIn: 'root'
})
export class FormDataService {

  private settingsUrl: string;
  private reportUrl: string;

  constructor(private http: HttpClient,
    private appConfigService: AppConfigService) {

  }

  getFormList(formType:string): Observable<FormInfo[]> {

    return this.http.get<FormInfo[]>(`${this.appConfigService.AppConfig.apiUrl_glp}/Forms`, {
      params: new HttpParams()
      .set('formType', formType)
    });

    // let mockList:FormInfo[]=[
    //   { group:'Group 1', name:'Name 1', downloadLink:'www.google1.com' },
    //   { group:'Group 2', name:'Name 2', downloadLink:'www.google2.com' }

    // ];


    // return of(mockList).pipe(delay(2000));
  }

  sendFormEmail(recipientemail:string, formname: string, downloadurl:string, userEmail:string, groupid:string, idType:string): Observable<any> {
    let request:SendFormEmailRequest = {
      recipientEmail:recipientemail,
      formName:formname,
      downloadLink: downloadurl,
      submitterEmail:userEmail,
      groupId:groupid
    };
    const httpOptions = {
      'responseType': 'text' as 'json'
    };
    // let request:recipientemail = {
    //   recipientEmail:'arun.vijayakumar@nebraskablue.com',
    //   formName:'Test Form',
    //   downloadLink: 'Attach dummy file link',
    //   submitterUserName:'arun.vijayakumar@testform.com',
    // };
    
    return this.http.post(`${this.appConfigService.AppConfig.apiUrl_glp}/EmailForms`, request, {
      params: new HttpParams()
          .set('type', idType)
  });
  }

}
