<div id="full-container">
    <div></div>
    <div class="grid-container">
        <div>
          <h6 id="FaqHeading">Help Center</h6>

          <p id="FaqDescription">
            If you have questions that aren’t answered here, call the number on the back of your ID card.
          </p>
        
          <mat-accordion displayMode="flat" multi  >
            <span *ngIf="!loading && !availableFaq">There are no entries.</span>
            <span *ngIf="loading">Loading entries...</span>

            <mat-expansion-panel (opened)="onExpand(item.title)" *ngFor="let item of (displayedRows$ | async);" class="mat-elevation-z2">
              <mat-expansion-panel-header class="faq-row">
                <mat-panel-title>
                  <span class="faq-title">{{item.title}}</span>

                </mat-panel-title>
                
              </mat-expansion-panel-header>
              <div >
                <span class="tab faq-body">{{item.body}}</span>
                <p></p>
                <a class="tab faq-link" (click)="openHelpLink(item.title,item.link)" onlick="gtag('event', 'openHelpLink', {'event_name': 'Clicked on Help Link','screen_name': 'HelpLink'});">{{item.linkDescription}}</a>
              </div>
            </mat-expansion-panel>

          </mat-accordion>
          <mat-paginator #paginator [hidden]="!availableFaq"
            [length]="totalRows$ | async"
            [pageIndex]="0"
            [pageSize]="10"
            [pageSizeOptions]="[1, 10, 25, 100]"
          ></mat-paginator>
        </div>
        
    </div>
    <div></div>
</div>


