<div class="bookOfBusinessBackground">
  <h6 style="color: white;" id="MemberHeading">Book of Business</h6>
</div>

<div class="row section-title upper text-center light">
</div>
<div id="full-container">
  <div></div>
  <div class="grid-container" style="overflow: auto;">
      <div class="mainSearchBar">
        <div id="searchContainer">
          <div style="display: flex; height: 60px; padding-top: 8px ; width: 110%">
            <input [disabled]="!enableSearch" type="text" class="input-search" #filter (keyup)="updateSearchText($event.target.value);" placeholder="Type to filter" #searchInput>
            <button [disabled]="!enableSearch" class="searchButton" (click)="clickFilter();" onclick="gtag('event', 'SearchGroup', {'event_name': 'Search Group','screen_name': 'Group List'});">Search</button>
            <button class="exportButton" (click)="exportToCSV()">Export Individual Data</button>
          </div>
        </div>

        
        <div class="filterSearchDiv" style="overflow: auto;">
          <label style="color: black;"for="standard-select">Broker ID </label>
          <div class="select">
            <select id="standard-select" (change)="updateBrokerIdFilter($event.target.value)" >
              <option *ngFor="let item of getBrokerIds()" [value]="item">{{ item }}</option>
            </select>
            <span class="focus"></span>
          </div>
        </div>

        <div class="filterSearchDiv" style="left: 35%; overflow: auto;">
          <label style="color: black; "for="standard-select">Original Effective Date</label>
            <div class="select">
              <select id="standard-select" (change)="updateRenewalMonthFilter($event.target.value)">
                <option *ngFor="let item of getRenewalMonths()" [value]="item">{{ item }}</option>
              </select>
              <span class="focus"></span>
            </div>
          </div>
          
          <div class="filterSearchDiv" style="left: 70%; overflow: auto;">
            <label style="color: black;" for="standard-select">Product Name</label>
            <div class="select">
              <select id="standard-select" (change)="updateProductNameFilter($event.target.value)">
                <option *ngFor="let item of getProductNames()" [value]="item">{{ item }}</option>
              </select>
              <span class="focus"></span>
            </div>
          </div>
      </div>


      <mat-table class="mat-elevation-z8" [dataSource]="dataSource" matSort matSortActive="SubscriberName" matSortDirection="asc" matSortDisableClear>

        <ng-container matColumnDef="SubscriberName">
          <mat-header-cell *matHeaderCellDef mat-sort-header [ngClass]="{'table-header':true}"><b>Subscriber Name</b></mat-header-cell>
          <mat-cell *matCellDef="let individual">{{ toCamelCase(individual.SubscriberName) }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="SubscriberID">
          <mat-header-cell *matHeaderCellDef mat-sort-header [ngClass]="{'table-header':true}"><b>Subscriber Id</b></mat-header-cell>
          <mat-cell *matCellDef="let individual">{{individual.SubscriberID}}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="BrokerName">
          <mat-header-cell *matHeaderCellDef mat-sort-header [ngClass]="{'table-header':true}"><b>Broker Name</b></mat-header-cell>
          <mat-cell *matCellDef="let individual">{{ toCamelCase(individual.BrokerName) }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="BrokerID">
          <mat-header-cell *matHeaderCellDef mat-sort-header [ngClass]="{'table-header':true}"><b>Broker ID</b></mat-header-cell>
          <mat-cell *matCellDef="let individual">{{individual.BrokerID}}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="OriginalEffectiveDate">
          <mat-header-cell *matHeaderCellDef mat-sort-header [ngClass]="{'table-header':true}"><b>Original Effective Date</b></mat-header-cell>
          <mat-cell *matCellDef="let individual">{{individual.OriginalEffectiveDate}}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="ProductName">
          <mat-header-cell *matHeaderCellDef mat-sort-header [ngClass]="{'table-header':true}"><b>Product Name</b></mat-header-cell>
          <mat-cell *matCellDef="let individual">{{individual.ProductName}}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="icon">
          <mat-header-cell *matHeaderCellDef [ngClass]="{'table-header':true}" > </mat-header-cell>
          <mat-cell *matCellDef="let individual">{{individual.icon}}
            <i class="arrow right"></i>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="GroupID">
          <mat-header-cell *matHeaderCellDef mat-sort-header [ngClass]="{hide}"><b>GroupID</b></mat-header-cell>
          <mat-cell *matCellDef="let individual">{{individual.GroupID}}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="loading">
          <mat-footer-cell *matFooterCellDef colspan="7">
            Loading data...
          </mat-footer-cell>
        </ng-container>
        <ng-container matColumnDef="noData">
          <mat-footer-cell *matFooterCellDef colspan="7">
            No data.
          </mat-footer-cell>
        </ng-container>


        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>

        <mat-row *matRowDef="let row; columns: displayedColumns"(click)="openSubscriber(row)"></mat-row>

        <mat-footer-row *matFooterRowDef="['loading']" [ngClass]="{'hide':dataSource!=null}"></mat-footer-row>
        <mat-footer-row *matFooterRowDef="['noData']" [ngClass]="{'hide':!(dataSource!=null && dataSource.data.length==0)}"></mat-footer-row>


      </mat-table>


      <mat-paginator [pageSize]="10"
                     [pageSizeOptions]="[10]" [showFirstLastButtons]="true"
                     [hidePageSize]="true"></mat-paginator>


    

  </div>
  <div></div>
</div>



