<div id="full-container">
    <div></div>
    <div class="grid-container">
        <div>

            <h6 id="ReportHeading">Reports</h6>
            <div id="searchContainer">
                <input [disabled]="!enableSearch" type="text" class="input-search" (keyup)="applyFilter($event.target.value);" placeholder="Search by Report Category or Name" #searchInput>

                <button [disabled]="!enableSearch" class="searchButton" (click)="clickFilter();" (click)="handleGtagClick( 'Reports', 'Search Button')">Search</button>
            </div>

            <mat-table class="reports-table mat-elevation-z8" [dataSource]="dataSource" matSort matSortActive="category" matSortDirection="desc" matSortDisableClear>
                <ng-container matColumnDef="category">

                    <mat-header-cell *matHeaderCellDef mat-sort-header [ngClass]="{'table-header':true}" (click)="toggleSortingOrder('Report Category')">Report Category</mat-header-cell>

                    <mat-cell *matCellDef="let report">{{getReportCategory(report.category)}}</mat-cell>
                    <!--<mat-cell *matCellDef="let report">Accounting</mat-cell>-->

                </ng-container>
                <ng-container matColumnDef="name">

                    <mat-header-cell *matHeaderCellDef mat-sort-header [ngClass]="{'table-header':true}" (click)="toggleSortingOrder('Report Name')">Report Name</mat-header-cell>

                    <mat-cell *matCellDef="let report">{{report.name}}</mat-cell>

                </ng-container>

                <ng-container matColumnDef="downloadLink">

                    <mat-header-cell *matHeaderCellDef [ngClass]="{'table-header':true}">Actions</mat-header-cell>

                    <mat-cell *matCellDef="let report">

                      <button class="downloadButton" (click)="downloadFile(report.category, report.name);" (click)="handleGtagClick('Reports', 'Row Actions - Download')">
                        <img class="downloadIcon" src="../../../../../assets/images/icons/download3.png" />
                        <span>Download</span>
                      </button>
                    </mat-cell>

                </ng-container>

                <ng-container matColumnDef="loading">
                    <mat-footer-cell *matFooterCellDef colspan="2">
                      Loading data...
                    </mat-footer-cell>
                  </ng-container>
                  <ng-container matColumnDef="noData">
                    <mat-footer-cell *matFooterCellDef colspan="2">
                      No data.
                    </mat-footer-cell>
                  </ng-container>


                <mat-header-row *matHeaderRowDef="displayedColumns" ></mat-header-row>

                <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>

                <mat-footer-row *matFooterRowDef="['loading']" [ngClass]="{'hide':dataSource!=null}"></mat-footer-row>
                <mat-footer-row *matFooterRowDef="['noData']" [ngClass]="{'hide':!(dataSource!=null && dataSource.data.length==0)}"></mat-footer-row>


            </mat-table>


            <mat-paginator [pageSize]="10"
            [pageSizeOptions]="[1, 10, 25, 100]" [showFirstLastButtons]="true"
            (click)="brokerClickPagination($event)"
            (page)="pageSizeChange($event.pageSize)">
                           </mat-paginator>


        </div>

    </div>
    <div></div>
</div>


