import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { AppConfigService } from '../../../app.config.service';
import { Faq } from '../models/Faq';
import { delay,map } from 'rxjs/operators';
import * as SampleJson from "../../../../assets/json/response.json";

@Injectable({
  providedIn: 'root'
})
export class FaqDataService {

  private settingsUrl: string;
  private reportUrl: string;

  constructor(private http: HttpClient,
    private appConfigService: AppConfigService) {

  }

  getFaq(): Observable<Faq[]> {
    return this.http.get<Faq[]>(`${this.appConfigService.AppConfig.apiUrl_glp}/Faq`);
  }

  getGuideFile(): Observable<any> {
    return this.http.get(`${this.appConfigService.AppConfig.apiUrl_glp}/GetGuide`, {
      observe: 'response',
      responseType: 'text'
    });

  }

}

