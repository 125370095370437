<div *ngIf="loaded">
<div style="width: 94%; margin-top: -10px; margin-left: 3%; margin-right: 3%;">
  <app-notification-list></app-notification-list>
</div>

<div *ngIf="!this.dataService.groupLeaderAccountIsSelected" style="padding-bottom: 40px;">
  <h1 style="padding-top: 50px; text-transform: none; margin-bottom: 50px; margin: 0 15%;">Welcome to EOA Portal, {{this.user}}!</h1>
  <div style="margin-top: 15px;">
  </div>

  <div class="mat-elevation-z8" style="margin: 0 15%; background-color: #f2f2f2; border-radius: 0px 0px 5px 5px;">

    <div class="searchHeader">
      <h1 style="color: white; margin-top: 13px; text-transform: none;">Group Search</h1>
    </div>
  
    <div class="mainSearchBar">
      <div style="display: flex; height: 60px; padding: 10px 0px 10px 20px; width: 150%">
        <input type="text" class="input-search" #filter (keyup)="updateSearchText($event.target.value);" placeholder="Type to filter" #searchInput>
  
        <button class="searchButton" (click)="clickFilter();">Search</button>
      </div>
    </div>
  
    <div class="table-container">
      <mat-table [dataSource]="dataSource" matSort matSortActive="GroupName" matSortDirection="asc" matSortDisableClear>
  
        <!-- GroupName Column -->
        <ng-container matColumnDef="GroupName">
          <mat-header-cell *matHeaderCellDef mat-sort-header [ngClass]="{'table-header':true}"><b>Group Name</b></mat-header-cell>
          <mat-cell *matCellDef="let element"> {{toCamelCase(element.GroupName)}} </mat-cell>
        </ng-container>
  
        <!-- GroupID Column -->
        <ng-container matColumnDef="GroupID">
          <mat-header-cell *matHeaderCellDef mat-sort-header style="font-size: 16px !important;"> Group ID </mat-header-cell>
          <mat-cell *matCellDef="let element"> {{element.GroupID}} </mat-cell>
        </ng-container>
  
        <!-- Member Count Column -->
        <ng-container matColumnDef="MemberCount">
          <mat-header-cell *matHeaderCellDef mat-sort-header style="font-size: 16px !important;"> Member Count </mat-header-cell>
          <mat-cell *matCellDef="let element"> {{element.MemberCount}} </mat-cell>
        </ng-container>
  
        <!-- MGN Column -->
        <ng-container matColumnDef="MGN">
          <mat-header-cell *matHeaderCellDef mat-sort-header style="font-size: 16px !important;"> MGN </mat-header-cell>
          <mat-cell *matCellDef="let element"> {{element.MGN}} </mat-cell>
        </ng-container>
  
        <!-- RenewalMonth Column -->
        <ng-container matColumnDef="RenewalMonth">
          <mat-header-cell *matHeaderCellDef mat-sort-header style="font-size: 16px !important;"> Renewal Month </mat-header-cell>
          <mat-cell *matCellDef="let element"> {{element.RenewalMonth}} </mat-cell>
        </ng-container>
  
        <!-- Define columns -->
        <mat-header-row style="font-size: 16px !important;" mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row style="cursor: pointer; font-size: 16px !important;" mat-row *matRowDef="let row; columns: displayedColumns" (click)="openMemberList(row.GroupID, row.GroupName)"></mat-row>
      
        <mat-footer-row></mat-footer-row>
  
      </mat-table>
      <mat-paginator [pageSize]="10" [pageSizeOptions]="[10]" [showFirstLastButtons]="true" [hidePageSize]="true" style="background-color: #f2f2f2; border-radius: 0px 0px 5px 5px;">
  
      </mat-paginator>
    </div>

  </div>
  
</div>

  <div *ngIf="this.dataService.groupLeaderAccountIsSelected" style="text-align: center; padding-top: 200px;">
    <h1>Loading Members...</h1>
  </div>
</div>

<div *ngIf="!loaded">
  
</div>
