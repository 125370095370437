<h1 mat-dialog-title style="text-transform: none;" class="title">
    {{title}}
</h1>

<div mat-dialog-content>
<p style="white-space: pre-line;">{{body}}</p>
</div>

<div mat-dialog-actions id="buttonsContainer">
    <!-- <div></div> -->
    <button class="buttonFont imageButton" (click)="onConfirm();">Close</button>
    <!-- <div></div> -->
</div>