import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NotificationDialogComponent } from '../notification-dialog/notification-dialog.component';

@Component({
  selector: 'app-email-dialog',
  templateUrl: './email-dialog.component.html',
  styleUrls: ['./email-dialog.component.scss']
})
export class EmailDialogComponent implements OnInit {
  emailDialogForm: FormGroup;

  @ViewChild('mailInput') mailInput: ElementRef;
  constructor(public dialogRef: MatDialogRef<EmailDialogComponent>, private formBuilder: FormBuilder) { 
    this.emailDialogForm = formBuilder.group({
      mailInput : new FormControl('',[Validators.required, Validators.pattern(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)])
    })
  }
  ngOnInit() {
  }

  onConfirm(): void {
    this.dialogRef.close({email:this.mailInput.nativeElement.value.trim().toLowerCase()});
  }

  onDismiss(): void {
    this.dialogRef.close(null);
  }
}
