<div id="full-container">
    <div></div>
    <div>
      <div class="buttonFont imageButton">
        <img class="imagePadding" src="../../../../../assets/images/icons/info.png">
        New here?
        <a class="guide-link" (click)="getGuide();"> Click to see our User Guide</a>
      </div>
    </div>
    <div></div>
</div>


