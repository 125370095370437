export class User {

  private userTypes: string[] = [];

  public firstName: string;

  public lastName: string;

  public email: string;

  public accountNumber: string;

  public phoneNumber: string;

  public isActivatedBroker: boolean;
  
  public isEHA: boolean;

  public userId: string;

  AddBrokerUserType = function () {
    if (this.GetUserType("broker") == null) {
      this.userTypes.push('broker');
    }
  };

  AddGroupLeaderUserType = function () {
    if (this.GetUserType("groupleader") == null) {
      this.userTypes.push('groupleader');
    }
  };

  GetUserType = function (name: string) {
    let type = null;
    this.userTypes.forEach(item => {
      if (item == name) {
        type = item;
      }
    });

    return type;
  };

  IsBroker = function (): boolean {
    var type = this.GetUserType("broker");

    if (type != null) { return true; }
    else { return false; }
  };

  IsGroupLeader = function (): boolean {
    var type = this.GetUserType("groupleader");

    if (type != null) { return true; }
    else { return false; }
  };

}
