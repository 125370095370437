import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';


if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));

window.dataLayer = window.dataLayer || [];
 
declare global {
  interface Window {
    dataLayer: any[];
    gTagPush: (click_location: string, click_action: string) => void;
  }
}

window.gTagPush = function (click_location: string, click_action: string) {
  window.dataLayer.push({'event': 'broker_click', 'click_location': click_location, 'click_action': click_action})
};