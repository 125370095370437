import { User } from './../models/user';
import { Injectable, Inject, Optional } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { first, lastValueFrom, observable, Observable } from 'rxjs';
import { AppConfigService } from '../app.config.service';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { ApprovalStatus } from '../models/ApprovalStatus';
import { GroupLeaderApprovalResponse } from '../models/GroupLeaderApprovalResponse';

@Injectable({
  providedIn: 'root',
})

export class UserService {
  private http: HttpClient;
  private configId: string;
  public userEmail: string;
  public groupLeaderAccountIsSelected: boolean = false;
  constructor(
    @Inject(HttpClient) http: HttpClient,
    public oidcSecurityService: OidcSecurityService,
    private appConfigService: AppConfigService) {
    this.http = http;
  }

  generateUserFromAccount(account: any): User {
    let user = new User();
    if (account) {

      user.phoneNumber = account.extension_PhoneNumber;
      user.isEHA = account.extension_GroupLeader_IsGroupLeader_EHA;
      user.userId = account.oid;

      if (account.extension_Broker_IsBroker) {
        user.AddBrokerUserType();
        user.isActivatedBroker = account.extension_Broker_IsActivated;
        user.firstName = account.given_name;
        user.lastName = account.family_name;
      }

      if (account.extension_AccountNumber != null) {
        user.AddGroupLeaderUserType();
        user.accountNumber = account.extension_AccountNumber;
        user.firstName = account.extension_FirstName;
        user.lastName = account.extension_LastName;
      }

      const emails = account.emails;
      if (emails?.length) {
        user.email = emails[0];
        this.userEmail = user.email;
      }
    }
    return user;
  }

  create(account: any): Observable<any> {
    const user = this.generateUserFromAccount(account);
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      }),
      'responseType': 'text' as 'json'
    };

    if (user.IsGroupLeader()) {
      return this.http.post(`${this.appConfigService.AppConfig.apiUrl_glp}/SaveEmployeeUser`, JSON.stringify(user), httpOptions);
    }
  }

  getUserGroup(groupId: string): Observable<any> {
    return this.http.get(`${this.appConfigService.AppConfig.apiUrl_glp}/GroupName`, {
      params: new HttpParams()
        .set('groupid', groupId)
    });
  }

  activateAccount(npn: number, firstName: string, lastName: string): Observable<any> {
    return new Observable(observer => {
      this.oidcSecurityService.getPayloadFromIdToken().subscribe((account) => {
        const user = this.generateUserFromAccount(account);
        this.http.get(`${this.appConfigService.AppConfig.apiUrl_glp}/ActivateAccount`, {
          params: new HttpParams()
            .set('NPN', npn.toString())
            .set('firstName', firstName)
            .set('lastName', lastName)
            .set('email', user.email)
            .set('userId', user.userId)
        }).subscribe((result) => { observer.next(result); observer.complete(); });
      });
    });
  }

  getMAApplicationStatuses(): Observable<any> {
    return new Observable(observer => {
      this.oidcSecurityService.getPayloadFromIdToken().subscribe((account) => {
        const user = this.generateUserFromAccount(account);
        this.http.get(`${this.appConfigService.AppConfig.apiUrl_glp}/MAApplicationStatus`, {
          params: new HttpParams()

            .set('email', user.email)
        }).subscribe((result) => { observer.next(result); observer.complete(); });
      });
    });
  }


  GetBrokerGroups(): Observable<any> {
    return new Observable(observer => {
      this.oidcSecurityService.getPayloadFromIdToken().subscribe((account) => {
        const user = this.generateUserFromAccount(account);
        this.http.get(`${this.appConfigService.AppConfig.apiUrl_glp}/BrokerGroups`, {
          params: new HttpParams()
            .set('email', user.email),
          observe: 'response'
        }).subscribe(
        (result) => {
          let retObject = {};
          retObject["status"] = result.status;
          retObject["success"] = result.ok;
          retObject["retList"] = result.body['retList'];
          observer.next(retObject);
          observer.complete();
        },
        (error) => {
          let result = {};
          result["success"] = false;
          result["status"] = error.status;
          observer.next(result);
          -observer.complete();
        });
      });
    });
  }

  async refreshSession() {
    this.oidcSecurityService.logoffAndRevokeTokens();
    this.oidcSecurityService.authorize();
  }

  getGroupLeaderApprovalStatus(): Observable<GroupLeaderApprovalResponse> {

    return new Observable(observer => {
      this.oidcSecurityService.getPayloadFromIdToken().subscribe((account) => {
        const user = this.generateUserFromAccount(account);

        if (user.IsGroupLeader()) {
          this.http.get<string[]>(`${this.appConfigService.AppConfig.apiUrl_glp}/IsEmployeeApproved`, {
            params: new HttpParams()
              .set('email', user.email)
              .set('accountNumber', user.accountNumber)
          }).subscribe((result) => {

            let groupLeaderApprovalResponse = new GroupLeaderApprovalResponse();
            groupLeaderApprovalResponse.isApproved = JSON.parse(result[0].toLowerCase());
            //groupLeaderApprovalResponse.accountType = result[1].toUpperCase();

            observer.next(groupLeaderApprovalResponse);
            observer.complete();
          },
          (error) => {
            let groupLeaderApprovalResponse = new GroupLeaderApprovalResponse();
            groupLeaderApprovalResponse.isApproved = false;
  
            observer.next(groupLeaderApprovalResponse);
            observer.complete();
          });

        } else {
          let groupLeaderApprovalResponse = new GroupLeaderApprovalResponse();
          groupLeaderApprovalResponse.isApproved = false;

          observer.next(groupLeaderApprovalResponse);
          observer.complete();
        }
      });
    });
  }

  getBrokerApprovalStatus(): ApprovalStatus {
    let approvalStatus = new ApprovalStatus();
    approvalStatus.isApproved = false;

    if (this.isBrokerUser()) {
      let user = this.getUser();
      approvalStatus.isApproved = user.isActivatedBroker;
    }

    return approvalStatus;
  }

  getUser(): User {

    let userData = null;
    this.oidcSecurityService.getPayloadFromIdToken().subscribe(
      (payload) => {
        userData = payload;
      });
    let userAccount = this.generateUserFromAccount(userData);
    return userAccount;

  }

  getGroupLeaderGroups(userEmail: string, groupIDs: string): Observable<any> {
    return new Observable(observer => {
      this.oidcSecurityService.getPayloadFromIdToken().subscribe((account) => {
        //const user = this.generateUserFromAccount(account);
        this.http.get(`${this.appConfigService.AppConfig.apiUrl_glp}/GroupLeaderGroups`, {
          params: new HttpParams()
            .set('email', userEmail)
            .set('groupId', groupIDs)
        }).subscribe((result) => { observer.next(result); observer.complete(); });
      });
    });
  }

  getIndividualList(email: string): Observable<any> {
    return new Observable(observer => {
      this.oidcSecurityService.getPayloadFromIdToken().subscribe((account) => {
        this.http.get(`${this.appConfigService.AppConfig.apiUrl_glp}/IndvSubscriptions`, {
          params: new HttpParams()
            .set('email', email)
        }).subscribe((result) => { observer.next(result); observer.complete(); });
      });
    });
  }

  isBrokerUser(): boolean {
    return this.getUser().IsBroker();
  }

  isGroupLeaderUser(): boolean {
    return this.getUser().IsGroupLeader();
  }

  isGroupLeaderEHAUser(): boolean {
    return this.getUser().isEHA;
  }

  setGroupLeaderAccountIsSelected(value: boolean): void {
    this.groupLeaderAccountIsSelected = value;
  }

  isGroupLeaderAccountSelected(): boolean {
    return this.groupLeaderAccountIsSelected;
  }
}
