import {
  AfterViewInit,
  Component,
  ElementRef,
  OnInit,
  ViewChild,
  ChangeDetectionStrategy
} from '@angular/core';

import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { FaqDataService } from '../../services/faq-data.service';
import { Observable,of,  } from 'rxjs';
import { map } from 'rxjs/operators';
import { MatPaginator,PageEvent } from "@angular/material/paginator";
import { MatSort, Sort } from "@angular/material/sort";
import {  } from "@angular/material/paginator";
import { fromMatSort, sortRows } from '../../../../utilities/datasource-utils';
import { fromMatPaginator, paginateRows } from '../../../../utilities/datasource-utils';
import { Faq } from '../../models/Faq';
import { ActivityLogService } from '../../../../services/activity-log.service';
import { ActivityType } from 'src/app/models/ActivityType';
import { LoggingService } from 'src/app/utilities/logging.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-faq-list',
  templateUrl: './faq-list.component.html',
  styleUrls: ['./faq-list.component.scss'],
})
export class FaqListComponent implements OnInit {
  groupId: number;
  availableFaq:boolean=false;
  loading:boolean;
  userEmail:string;

  selectedTitle: string;

  @ViewChild(MatPaginator) paginator: MatPaginator;

  displayedRows$: Observable<Faq[]>;
  totalRows$: Observable<number>;


  constructor(
    private dataService: FaqDataService,
    private dialog: MatDialog,
    public oidcSecurityService: OidcSecurityService,
    private userService: UserService,
    private logService: ActivityLogService,
    private loggingService: LoggingService
  ) {}

  ngOnInit() {
    this.oidcSecurityService.getPayloadFromIdToken().subscribe((account)=>{

    if (account) {
      this.groupId = +account.extension_AccountNumber;
      this.loading=true;
      if(account.emails?.length>0){
        this.userEmail = account.emails[0];
      }
      this.logService.log(this.userEmail, this.userService.isBrokerUser(), ActivityType.Help,"").subscribe(
        (data) => {
        },
        (err) => {
          this.loggingService.logException(err, 3);
        }
      );
      this.dataService.getFaq().subscribe((list) => {
        this.loading=false;
        if(list.length>0){
          this.availableFaq=true;
        }
        const pageEvents$: Observable<PageEvent> = fromMatPaginator(this.paginator);

        const rows$ = of(list);

        this.totalRows$ = rows$.pipe(map(rows => rows.length));
        this.displayedRows$ = rows$.pipe(paginateRows(pageEvents$));
      },e=>this.loading=false);
    }
  });
    
  }

  openHelpLink(faqTitle:string,linkUrl:string){
    this.logService.log(this.userEmail, this.userService.isBrokerUser(), ActivityType.ClickFaqLink,faqTitle).subscribe(
      (data) => {
      },
      (err) => {
        this.loggingService.logException(err, 3);
      }
    );

    const link = document.createElement('a');
    link.setAttribute('href', linkUrl.startsWith('http') ? linkUrl : '//'+linkUrl);
    link.setAttribute('target', '_blank');
    document.body.appendChild(link);
    link.click();
    link.remove();
  }

  onExpand(faqTitle:string){
    this.logService.log(this.userEmail,this.userService.isBrokerUser(),ActivityType.ExpandHelp,faqTitle).subscribe(
      (data) => {
      },
      (err) => {
        this.loggingService.logException(err, 3);
      }
    );
  }

}
