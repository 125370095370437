import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FaqComponent } from './faq.component';
import { MaterialModule } from '../../material/material.module';
import { FaqListComponent } from './components/faq-list/faq-list.component';
import { FaqGuideComponent } from './components/faq-guide/faq-guide.component';



@NgModule({
  declarations: [FaqComponent, FaqListComponent,FaqGuideComponent],
  imports: [
    CommonModule,
    MaterialModule
  ]
})
export class FaqModule { }
