import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-expanded-notifications',
  templateUrl: './expanded-notifications.component.html',
  styleUrls: ['./expanded-notifications.component.scss']
})
export class ExpandedNotificationsComponent  {

}
