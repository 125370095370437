import {
  AfterViewInit,
  Component,
  ElementRef,
  OnInit,
  ViewChild,
  ChangeDetectionStrategy
} from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { FaqDataService } from '../../services/faq-data.service';
import { ActivityLogService } from '../../../../services/activity-log.service';
import { ActivityType } from 'src/app/models/ActivityType';
import { AlertDialogComponent } from 'src/app/glp-common/components/alert-dialog/alert-dialog.component';
import { LoggingService } from 'src/app/utilities/logging.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-faq-guide',
  templateUrl: './faq-guide.component.html',
  styleUrls: ['./faq-guide.component.scss'],
})
export class FaqGuideComponent implements OnInit {
  groupId: number;
  loading:boolean;
  userEmail:string;

  selectedTitle: string;




  constructor(
    private dataService: FaqDataService,
    public oidcSecurityService: OidcSecurityService,
    private userService: UserService,
    private logService: ActivityLogService,
    private dialog: MatDialog,
    private loggingService: LoggingService
  ) {}

  ngOnInit() {
    this.oidcSecurityService.getPayloadFromIdToken().subscribe((account)=>{

    if (account) {
      this.groupId = +account.extension_AccountNumber;
      this.loading=true;
      if(account.emails?.length>0){
        this.userEmail = account.emails[0];
      }
    }
  });
  }



  getGuide() {
    this.logService.log(this.userEmail, this.userService.isBrokerUser(), ActivityType.ClickFaqGuide,"").subscribe(
      (data) => {
      },
      (err) => {
        this.loggingService.logException(err, 3);
      }
    );
    this.dataService.getGuideFile().subscribe(
      (data) => {
        this.downloadResource(data.body, name);
      },
      (err) => {
        // console.log(err);
        this.loggingService.logException(err, 3);
        let friendlyError = 'An error occurred while downloading the guide';

        const errorDialogConfig = new MatDialogConfig();
        errorDialogConfig.data = {
          title: 'Result Download Guide',
          message: friendlyError,
        };
        this.dialog.open(AlertDialogComponent, errorDialogConfig);
      }
    );
  }

  private sanitizeFilename(filename) {
    // Define a regular expression to match safe filename characters
    const safeCharactersRegex = /^[a-zA-Z0-9_.-]+$/;

    // Return true if the filename contains only safe characters, false otherwise
    return safeCharactersRegex.test(filename);
  }

  private downloadResource(url, filename) {
    // If no filename is set, use filename from URL
    if (!filename) {
      const match = url.match(/\/([^/#?]+)[^/]*$/);
      if (match) {
        filename = match[1];
      } else {
        console.error("Invalid URL");
        return;
      }
    } else if (!this.sanitizeFilename(filename)) {
      console.error("Invalid filename");
      return;
    }

    fetch(url, {
      headers: new Headers({
        Origin: window.location.origin,
      }),
      mode: 'cors',
    })
      .then((response) => response.blob())
      .then((blob) => this.forceDownload(blob, filename))
      .catch((e) => console.error(e));
  }

  private forceDownload(blob, filename) {
    // Create an anchor element
    const anchor = document.createElement('a');
    anchor.href = window.URL.createObjectURL(blob);
    anchor.setAttribute('download', filename);

    // Programmatically trigger a click event on the anchor
    anchor.click();

    // Clean up
    window.URL.revokeObjectURL(anchor.href);
  }

}
