import { FormsModule } from './../forms/forms.module';
import { EmailDialogComponent } from './components/email-dialog/email-dialog.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HomeComponent } from './home.component';
import { MaterialModule } from '../../material/material.module';
import { MemberDetailComponent } from './components/member-detail/member-detail.component';
import { MemberInformationComponent } from './components/member-information/member-information.component';
import { NotificationDialogComponent } from './components/notification-dialog/notification-dialog.component';
import { NotificationListComponent } from './components/notification-list/notification-list.component';
import { GlpCommonModule } from 'src/app/glp-common/glp-common.module';
import { AlertDialogComponent } from 'src/app/glp-common/components/alert-dialog/alert-dialog.component';
import { ConfirmDialogComponent } from 'src/app/glp-common/components/confirm-dialog/confirm-dialog.component';
import { MembersListModule } from './components/members-list/members-list.module';
import { GroupleaderlistComponent } from './components/groupleaderlist/groupleaderlist.component';


@NgModule({
  declarations: [HomeComponent, MemberDetailComponent, MemberInformationComponent, NotificationListComponent, GroupleaderlistComponent],
  imports: [
    CommonModule,
    FormsModule,
    MaterialModule,
    GlpCommonModule,
    MembersListModule
  ],
  entryComponents:[MemberDetailComponent, MemberInformationComponent, AlertDialogComponent, ConfirmDialogComponent, NotificationDialogComponent, EmailDialogComponent],
  exports:[NotificationListComponent]
})
export class HomeModule { }
