import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DocumentsComponent } from './documents.component';
import { DocumentsListComponent } from './components/documents-list/documents-list.component';
import { MaterialModule } from '../../material/material.module';
import { AlertDialogComponent } from 'src/app/glp-common/components/alert-dialog/alert-dialog.component';
import { ConfirmDialogComponent } from 'src/app/glp-common/components/confirm-dialog/confirm-dialog.component';


@NgModule({
  declarations: [DocumentsComponent, DocumentsListComponent],
  imports: [
    CommonModule,
    MaterialModule
  ],
  entryComponents:[AlertDialogComponent, ConfirmDialogComponent]
})
export class DocumentsModule { }
