<h1 mat-dialog-title style="text-transform: none;">
    {{title}}
</h1>

<form [formGroup]="sendDialogForm">
<div mat-dialog-content>
<p style="white-space: pre-line;">{{message}}</p>
</div>

<div id="emailContainer" class="emailContainer">
    <mat-form-field>
        <mat-label>example@email.com"</mat-label>
        <input matInput type="email" formControlName="emailidInput" class="input-email" email required  #emailidInput name="emailidInput">
        <mat-error *ngIf="sendDialogForm.controls['emailidInput'].hasError('required')">Please provide an email</mat-error>
        <mat-error *ngIf="!sendDialogForm.controls['emailidInput'].hasError('required') && (sendDialogForm.controls['emailidInput'].hasError('email') || sendDialogForm.controls['emailidInput'].status === 'INVALID')">Please provide a valid email</mat-error>
    </mat-form-field>
</div>

<br>
<div mat-dialog-actions id="buttonsContainer">
    <div class="directButtonContainer">
        <button class="buttonFont imageButton" (click)="onDismiss();">Cancel</button>
    </div>
    <div class="directButtonContainer">
        <button class="buttonFont imageButton " [ngClass]="!sendDialogForm.valid ? 'imageButtonDisabled' : ''" [disabled]="!sendDialogForm.valid" (click)="onConfirm();">Send</button>
    </div>
</div>
</form>
