import {
  AfterViewInit,
  Component,
  ElementRef,
  OnInit,
  ViewChild,
  ChangeDetectionStrategy
} from '@angular/core';

import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { ExpandedNotificationsDataService } from '../../services/expanded-notifications-data.service';
import { Observable,of,  } from 'rxjs';
import { map } from 'rxjs/operators';
import { MatPaginator,PageEvent } from "@angular/material/paginator";
import { MatSort, Sort } from "@angular/material/sort";
import {  } from "@angular/material/paginator";
import { fromMatSort, sortRows } from '../../../../utilities/datasource-utils';
import { fromMatPaginator, paginateRows } from '../../../../utilities/datasource-utils';
import { GLPExpandedNotification } from '../../models/GLPExpandedNotification';
import { ActivityLogService } from '../../../../services/activity-log.service';
import { ActivityType } from 'src/app/models/ActivityType';
import { LoggingService } from 'src/app/utilities/logging.service';
import { MemberDataService } from 'src/app/content/home/services/member-data.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-expanded-notifications-list',
  templateUrl: './expanded-notifications-list.component.html',
  styleUrls: ['./expanded-notifications-list.component.scss'],
})
export class ExpandedNotificationsListComponent implements OnInit {
  userEmail:string;
  availableNotifications:boolean=false;
  loading:boolean;

  selectedTitle: string;

  // dataSource: MatTableDataSource<any>;

  // displayedColumns: string[] = [
  //   'title'
  // ];

  //@ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  displayedRows$: Observable<GLPExpandedNotification[]>;
  totalRows$: Observable<number>;


  constructor(
    private dataService: ExpandedNotificationsDataService,
    private dialog: MatDialog,
    public oidcSecurityService: OidcSecurityService,
    private userService: UserService,
    private logService: ActivityLogService,
    private loggingService: LoggingService,
    private memberData: MemberDataService
  ) {}

  ngOnInit() {
    this.oidcSecurityService.getPayloadFromIdToken().subscribe((account)=>{

    if (account) {
      this.loading=true;
      if(account.emails?.length>0){
        this.userEmail = account.emails[0];
      }
      this.dataService.getNotifications().subscribe((list) => {
        this.loading=false;
        if(list.length>0){
          this.availableNotifications=true;
        }
        const pageEvents$: Observable<PageEvent> = fromMatPaginator(this.paginator);

        const rows$ = of(list);

        this.totalRows$ = rows$.pipe(map(rows => rows.length));
        //this.displayedRows$ = rows$.pipe(sortRows(sortEvents$), paginateRows(pageEvents$));
        this.displayedRows$ = rows$.pipe(paginateRows(pageEvents$));
      },e=>this.loading=false);
    }
  });

    
  }

  onExpand(title:string){
    this.logService.log(this.userEmail, this.userService.isBrokerUser(), ActivityType.ViewNotificationDetail,title).subscribe(
      (data) => {
        window.dataLayer.push({'event': 'broker_click', 'click_location': 'Notifications', 'click_action': 'Notifcation Expand'})
      },
      (err) => {
        this.loggingService.logException(err, 4);
      }
    );
  }

  brokerClickPagination(event: any){
    if(event.srcElement.parentElement.ariaLabel != null){
      window.dataLayer.push({'event': 'broker_click', 'click_location': 'Notifications', 'click_action': `Pagination - ${event.srcElement.parentElement.ariaLabel}`})
    }
   }

  pageSizeChange(newPageSize: number) {
    window.dataLayer.push({'event': 'broker_click', 'click_location':'Notifications', 'click_action': `Filter - ${newPageSize} items per page`});
  }

}
